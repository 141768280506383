import { useTranslation } from 'libs/i18n';
import { RegistrationAddress } from 'modules/onboarding/legal/components';

import { useRegistrationAddress } from '../hooks';
import { useOnboardingContext } from '../hooks/useOnboardingContext';
import { PageComponentProps } from '../types';

export const RegistrationAddressStep = ({ canGoBack }: PageComponentProps) => {
  const {
    countries,
    countriesLoading,
    initialValues,
    submitLoading,
    onSubmit,
    goToBack,
  } = useRegistrationAddress();

  const { step } = useOnboardingContext();

  const { t } = useTranslation();

  return (
    <RegistrationAddress
      canGoBack={canGoBack}
      countries={countries}
      countriesLoading={countriesLoading}
      initialValues={initialValues}
      step={step}
      submitLoading={submitLoading}
      subTitle={t('onboarding.legalClientHead.registrationAddress.subtitle')}
      title={t('onboarding.legalClientHead.registrationAddress.title')}
      onGoBack={goToBack}
      onSubmit={onSubmit}
    />
  );
};
