// eslint-disable-next-line no-restricted-imports
import { LegalClientBranchFragment } from 'api/generated/graphql';
import { LegalClientBranchEntity } from 'api/types/entity';
import { lodash } from 'helpers';

import { parseLegalClientTaxResidency } from '../../helpers/parseLegalClientTaxResidency';

import { parseLegalClientBranchContacts } from './parseLegalClientBranchContacts';
import { parseLegalClientBranchLicenseInfo } from './parseLegalClientBranchLicenseInfo';
import { parseLegalClientBranchStateRegistration } from './parseLegalClientBranchStateRegistration';

export const parseLegalClientBranchEntity = (
  data: LegalClientBranchFragment
): LegalClientBranchEntity => ({
  id: data.id,
  fullName: data.fullName,
  legalClientId: data.legalClientId || undefined,
  onboarding: {
    routes: lodash.compact(data.onboarding?.routes),
  },
  actualAddress: data.actualAddress,
  legalAddress: data.legalAddress,
  isLicensed: data.isLicensed ?? undefined,
  taxResidences: data.taxResidences
    ? lodash.compact(data.taxResidences).map(parseLegalClientTaxResidency)
    : undefined,
  stateRegistration: parseLegalClientBranchStateRegistration(
    data.stateRegistration
  ),
  contacts: parseLegalClientBranchContacts(data.contacts),
  licenseInfo: parseLegalClientBranchLicenseInfo(data.licenseInfo),
});
