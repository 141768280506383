import { useRef, useState } from 'react';

import { LegalClientBodyEntity } from 'api/types/entity/legal/legalClientBody';
import { ConfirmationDialog } from 'components';
import { useTranslation } from 'libs/i18n';
import { Button, Stack } from 'libs/ui';
import { useDialog } from 'libs/ui/Dialog/useDialog';
import { Icon } from 'libs/ui/Icon';

import { DATAX_ADD_ITEM } from '../../../../common/constants';
import {
  useFormContext,
  Field,
  MAX_AUTHORITIES,
  CompanyStructureFormType,
} from '../../../forms/companyStructureForm';

import { BodyItem } from './BodyItem';

export interface FormContentProps {
  bodies: LegalClientBodyEntity[];
  onAdd: (v: CompanyStructureFormType) => Promise<any>;
  onEdit: (v: NonNullable<CompanyStructureFormType>) => Promise<any>;
  onDelete: (id: string) => Promise<any>;
}

export const FormContent = ({
  bodies,
  onAdd,
  onEdit,
  onDelete,
}: FormContentProps) => {
  const [loading, setLoading] = useState(false);

  const editItemIndex = useRef<number>();

  const { values, setValues, validateField, setErrors } = useFormContext();

  const { t } = useTranslation();

  const { isOpen, onClose, open } = useDialog();

  const handleAdd = () => {
    editItemIndex.current = undefined;
    setValues({
      type: '',
      name: '',
      id: undefined,
    });
    open();
  };

  const handleEdit = (body: LegalClientBodyEntity) => {
    setValues({ ...body });
    open();
  };

  const handleClose = () => {
    setValues({
      type: '',
      name: '',
      id: undefined,
    });
    onClose();
    setErrors({});
  };

  const onConfirm = async () => {
    const isLabelValid = await validateField('type');
    const isNamelValid = await validateField('name');

    if (isLabelValid && isNamelValid) {
      setLoading(true);
      try {
        if (values.id) {
          await onEdit(values).catch();
        } else {
          await onAdd(values).catch();
        }
        setLoading(false);
        handleClose();
      } catch (error) {
        setLoading(false);
      }
    }
  };

  return (
    <Stack spacing={32}>
      {bodies.map((value) => (
        <BodyItem
          key={value.id}
          body={value}
          onDelete={onDelete}
          onEdit={handleEdit}
        />
      ))}

      <Button
        fullWidth
        datax={DATAX_ADD_ITEM}
        disabled={bodies.length >= MAX_AUTHORITIES}
        label={t('onboarding.companyStructure.addButton')}
        mode="medium"
        startIcon={<Icon.Plus />}
        onClick={handleAdd}
      />

      <ConfirmationDialog
        fullWidth
        confirmButtonLabel={t('common.save')}
        isOpen={isOpen}
        loading={loading}
        title={t('onboarding.companyStructure.dialogTitle')}
        onBack={handleClose}
        onClose={handleClose}
        onConfirm={onConfirm}
      >
        <Stack spacing={36}>
          <Field.Text
            fullWidth
            helperTextAbsolute
            label={t('onboarding.companyStructure.authorityType.label')}
            name="type"
            placeholder={t(
              'onboarding.companyStructure.authorityType.placeholder'
            )}
          />
          <Field.Text
            fullWidth
            helperTextAbsolute
            label={t('onboarding.companyStructure.authority.label')}
            name="name"
            placeholder={t('onboarding.companyStructure.authority.placeholder')}
          />
        </Stack>
      </ConfirmationDialog>
    </Stack>
  );
};
