import { Stack } from 'libs/ui';
import { CSSObject, styled } from 'libs/ui/styled';

export const BodyStyled = styled(Stack)(() => ({
  overflow: 'hidden',

  '.companyStructure-body-textContent': {
    overflow: 'hidden',

    '.companyStructure-body-text': {
      textOverflow: 'ellipsis',
      textWrap: 'nowrap',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    } as CSSObject,
  },
}));
