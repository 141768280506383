import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientGetLegalHeadLazyQuery } from 'api/generated/graphql';
import { useError } from 'api/helpers';

import { parseLegalClientLegalHeadEntity } from './helpers';

export const useLegalClientGetLegalHeadLazy = () => {
  const [query, { data, loading, error: apolloError }] =
    useLegalClientGetLegalHeadLazyQuery();

  const error = useError(apolloError);

  const getLegalHead = useCallback(
    async (legalId: string, legalHeadId: string) => {
      const res = await query({ variables: { id: legalId, legalHeadId } });
      if (res.data?.legalClientGetLegalHead) {
        return parseLegalClientLegalHeadEntity(
          res.data?.legalClientGetLegalHead
        );
      }
      return null;
    },
    [query]
  );

  return {
    legalHead: data?.legalClientGetLegalHead
      ? parseLegalClientLegalHeadEntity(data.legalClientGetLegalHead)
      : undefined,
    loading,
    getLegalHead,
    error,
  };
};
