import {
  OnboardingBiographyStep,
  OnboardingCitizenshipStep,
  OnboardingRegistrationAddressStep,
  OnboardingTaxResidencyStep,
  PepConnectionStep,
  FinancingSourcesStep,
  DebtsStep,
  WithdrawalAccountsStep,
  ConfirmationDataStep,
  SignatureSamplesStep,
  AccountsInOrganizationsStep,
  OrderSubmissionContactsStep,
  KycStep,
} from './steps';
import { RoutePage } from './types';

enum Page {
  citizenship = 'citizenship',
  taxResidency = 'taxResidency',
  registrationAddress = 'registrationAddress',
  kyc = 'kyc',
  biography = 'biography',
  bankAccounts = 'bankAccounts',
  financingSources = 'financingSources',
  pepConnection = 'pepConnection',
  debts = 'debts',
  withdrawalAccounts = 'withdrawalAccounts',
  signatureSamples = 'signatureSamples',
  confirmationData = 'confirmationData',
  orderSubmissionContacts = 'orderSubmissionContacts',
}

const getItem = (
  page: Page,
  component: RoutePage['component'],
  componentProps: RoutePage['componentProps'] = { canGoBack: true }
): [Page, RoutePage] => [page, { component, componentProps }];

export const pages = new Map<Page, RoutePage>([
  getItem(Page.citizenship, OnboardingCitizenshipStep, { canGoBack: false }),
  getItem(Page.taxResidency, OnboardingTaxResidencyStep),
  getItem(Page.registrationAddress, OnboardingRegistrationAddressStep),
  getItem(Page.kyc, KycStep),

  getItem(Page.biography, OnboardingBiographyStep, { canGoBack: false }),
  getItem(Page.bankAccounts, AccountsInOrganizationsStep),
  getItem(Page.financingSources, FinancingSourcesStep),
  getItem(Page.pepConnection, PepConnectionStep),
  getItem(Page.debts, DebtsStep),
  getItem(Page.withdrawalAccounts, WithdrawalAccountsStep),
  getItem(Page.orderSubmissionContacts, OrderSubmissionContactsStep),
  getItem(Page.signatureSamples, SignatureSamplesStep),
  getItem(Page.confirmationData, ConfirmationDataStep),
]);
