import { useTranslation } from 'libs/i18n';
import { Button, Stack, Typography } from 'libs/ui';

import { ContainerStyled } from './ConfirmationDialog.styles';
import { ConfirmationDialogContentProps } from './types';

export const ConfirmationDialogContent = ({
  title,
  subtitle,
  confirmButtonLabel,
  backButtonLabel,
  onConfirm,
  onBack,
  children,
  className,
  loading,
  headerCentered,
  buttonConfirm,
  hideButtonConfirm,
  confirmButtonColorVariant,
}: ConfirmationDialogContentProps) => {
  const { t } = useTranslation();

  const ButtonConfirm = buttonConfirm ?? Button;

  return (
    <ContainerStyled className={className}>
      <Stack spacing={36}>
        <Stack alignItems={headerCentered ? 'center' : undefined} spacing={20}>
          <Typography
            align="center"
            datax="dialog-title"
            variant="title"
            variantMobile="subtitle"
          >
            {title}
          </Typography>
          {!!subtitle && (
            <Typography
              align={headerCentered ? 'center' : undefined}
              className="confirmDialog-subtitle"
              datax="dialog-subtitle"
              variantMobile="caption"
            >
              {subtitle}
            </Typography>
          )}
        </Stack>

        {children}

        <Stack spacing={12}>
          {!hideButtonConfirm && (
            <ButtonConfirm
              colorVariant={confirmButtonColorVariant}
              datax="confirmDialog-buttonConfirm"
              label={confirmButtonLabel ?? t('common.confirm')}
              loading={loading}
              onClick={onConfirm}
            />
          )}
          <Button
            datax="confirmDialog-buttonBack"
            label={backButtonLabel ?? t('common.back')}
            mode={hideButtonConfirm ? 'outlined' : 'text'}
            onClick={onBack}
          />
        </Stack>
      </Stack>
    </ContainerStyled>
  );
};
