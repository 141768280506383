import { FirstHeads } from '../components';
import { useFirstHeads } from '../hooks';
import { useOnboardingContext } from '../hooks/useOnboardingContext';
import { PageComponentProps } from '../types';

export const FirstHeadsStep = ({ canGoBack }: PageComponentProps) => {
  const {
    firstHeads,
    errorFirstHeadsIds,
    deleteLoading,
    onAdd,
    onSubmit,
    goToBack,
    onEdit,
    onDelete,
  } = useFirstHeads();

  const { step } = useOnboardingContext();

  return (
    <FirstHeads
      canGoBack={canGoBack}
      deleteLoading={deleteLoading}
      errorFirstHeadsIds={errorFirstHeadsIds}
      firstHeads={firstHeads}
      step={step}
      onAdd={onAdd}
      onDelete={onDelete}
      onEdit={onEdit}
      onGoBack={goToBack}
      onSubmit={onSubmit}
    />
  );
};
