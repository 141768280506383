import { FC } from 'react';

import { assertNever } from 'helpers';
import { useTranslation } from 'libs/i18n';

import { OnboardingStatusDefault } from '../OnboardingStatusDefault';

import { OnboardingStatusProps } from './OnboardingStatus.types';

export const OnboardingStatus: FC<OnboardingStatusProps> = ({
  onboardingStatus,
  ...onboardingStatusProps
}) => {
  const { t } = useTranslation();

  switch (onboardingStatus) {
    case 'openingProcess':
    case 'inProcess':
    case 'rejected':
    case 'awaitingPayment':
    case 'success':
    case 'identityConfirmed':
      return (
        <OnboardingStatusDefault
          email={onboardingStatusProps.email}
          onboardingStatus={onboardingStatus}
          statusText={t(`onboarding-status.${onboardingStatus}.status`)}
          text={t(`onboarding-status.${onboardingStatus}.text`)}
          title={t(`onboarding-status.${onboardingStatus}.title`)}
        />
      );

    default:
      return assertNever('Unexpected onboarding status', onboardingStatus);
  }
};
