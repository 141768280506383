import {
  RoleInCompanyStep,
  CitizenshipStep,
  TaxResidencyStep,
  RegistrationAddressStep,
  BiographyStep,
  PepConnectionStep,
  CompanyNameStep,
  CompanyRegistrationPlaceStep,
  CompanyRegistrationAddressStep,
  CompanyTaxResidencyStep,
  CompanyActivitiesStep,
  OrganizationalAndLegalFormStep,
  CompanyAccountsInOrganizationsStep,
  CompanyContactsStep,
  CompanyStateRegistrationStep,
  CompanyDebtsStep,
  KycStep,
  CompanyLicenseStep,
  CompanyConfirmStructureStep,
  CompanyStructureStep,
  CompanyConstituentDocsStep,
  CompanyHeadsStep,
  CompanyFinancingSourcesStep,
  CompanyFinancialMonitoringStep,
  CompanyBusinessRrelationshipStep,
  WithdrawalAccountsStep,
  LegalRepresentativeCompanyNameStep,
  LegalRepresentativeCompanyTaxResidencyStep,
  LegalRepresentativeCompanyStateRegistrationStep,
  LegalRepresentativeCompanyRegistrationAddressStep,
  OrderSubmissionContactsStep,
  LegalRepresentativeCompanyConstituentDocsStep,
  CompanyBranchesStep,
  ConfirmationDataStep,
  SignatureSamplesStep,
  InvoiceForPaymentStep,
  InvoiceConfirmForPaymentStep,
} from './steps';
import { CompanyBeneficiariesStep } from './steps/CompanyBeneficiariesStep';
import { RoutePage } from './types';

export enum Page {
  roleInCompany = 'roleInCompany',
  citizenship = 'citizenship',
  taxResidency = 'taxResidency',
  registrationAddress = 'registrationAddress',
  biography = 'biography',
  pepConnection = 'pepConnection',
  companyName = 'companyName',
  companyRegistrationPlace = 'companyRegistrationPlace',
  companyRegistrationAddress = 'companyRegistrationAddress',
  companyTaxResidency = 'companyTaxResidency',
  companyActivities = 'companyActivities',
  organizationalAndLegalForm = 'organizationalAndLegalForm',
  companyAccountsInOrganizations = 'companyAccountsInOrganizations',
  companyContacts = 'companyContacts',
  companyStateRegistration = 'companyStateRegistration',
  companyConfirmStructureStep = 'companyConfirmStructureStep',
  companyDebts = 'companyDebts',
  kyc = 'kyc',
  companyLicense = 'companyLicense',
  companyConstituentDocs = 'companyConstituentDocs',
  companyStructure = 'companyStructure',
  companyFinancingSources = 'companyFinancingSources',
  companyFinancialMonitoring = 'companyFinancialMonitoring',
  heads = 'heads', // список руководитетелей
  beneficiary = 'beneficiary',
  companyBusinessRrelationship = 'companyBusinessRrelationship',
  withdrawalAccounts = 'withdrawalAccounts',
  legalRepresentativeCompanyName = 'legalRepresentativeCompanyName',
  legalRepresentativeCompanyTaxResidency = 'legalRepresentativeCompanyTaxResidency',
  legalRepresentativeCompanyRegistrationAddress = 'legalRepresentativeCompanyRegistrationAddress',
  legalRepresentativeCompanyStateRegistration = 'legalRepresentativeCompanyStateRegistration',
  legalRepresentativeCompanyConstituentDocs = 'legalRepresentativeCompanyConstituentDocs',
  orderSubmissionContacts = 'orderSubmissionContacts',
  branches = 'branches',
  confirmationData = 'confirmationData',
  signatureSamples = 'signatureSamples',
  invoiceForPayment = 'invoiceForPayment',
  invoiceConfirmForPayment = 'invoiceConfirmForPayment',
}

const getItem = (
  page: Page,
  component: RoutePage['component'],
  componentProps: RoutePage['componentProps'] = { canGoBack: true }
): [Page, RoutePage] => [page, { component, componentProps }];

const basePages = new Map<Page, RoutePage>([
  getItem(Page.roleInCompany, RoleInCompanyStep, {
    canGoBack: false,
  }),
  getItem(Page.citizenship, CitizenshipStep),
  getItem(Page.taxResidency, TaxResidencyStep),
  getItem(Page.registrationAddress, RegistrationAddressStep),
  getItem(Page.biography, BiographyStep),
  getItem(Page.pepConnection, PepConnectionStep),
]);

export const roleDirector = new Map<Page, RoutePage>([
  ...basePages,
  getItem(Page.companyName, CompanyNameStep),
  getItem(Page.companyTaxResidency, CompanyTaxResidencyStep),
  getItem(Page.companyRegistrationPlace, CompanyRegistrationPlaceStep),
  getItem(Page.companyRegistrationAddress, CompanyRegistrationAddressStep),
  getItem(Page.companyStateRegistration, CompanyStateRegistrationStep),
  getItem(Page.organizationalAndLegalForm, OrganizationalAndLegalFormStep),
  getItem(Page.companyActivities, CompanyActivitiesStep),
  getItem(Page.companyLicense, CompanyLicenseStep),
  getItem(Page.companyConstituentDocs, CompanyConstituentDocsStep),
  getItem(Page.companyConfirmStructureStep, CompanyConfirmStructureStep),
  getItem(Page.kyc, KycStep),
  getItem(Page.companyContacts, CompanyContactsStep, {
    canGoBack: false,
  }),
  getItem(Page.branches, CompanyBranchesStep),
  getItem(
    Page.companyAccountsInOrganizations,
    CompanyAccountsInOrganizationsStep
  ),
  getItem(Page.companyFinancingSources, CompanyFinancingSourcesStep),
  getItem(Page.companyDebts, CompanyDebtsStep),
  getItem(Page.companyFinancialMonitoring, CompanyFinancialMonitoringStep),
  getItem(Page.companyBusinessRrelationship, CompanyBusinessRrelationshipStep),
  getItem(Page.withdrawalAccounts, WithdrawalAccountsStep),
  getItem(Page.orderSubmissionContacts, OrderSubmissionContactsStep),
  getItem(Page.signatureSamples, SignatureSamplesStep),
  getItem(Page.confirmationData, ConfirmationDataStep),
  getItem(Page.invoiceForPayment, InvoiceForPaymentStep, { canGoBack: false }),
  getItem(Page.invoiceConfirmForPayment, InvoiceConfirmForPaymentStep),
]);

export const roleOneOfDirectors = new Map<Page, RoutePage>([
  ...basePages,
  getItem(Page.companyName, CompanyNameStep),
  getItem(Page.companyTaxResidency, CompanyTaxResidencyStep),
  getItem(Page.companyRegistrationPlace, CompanyRegistrationPlaceStep),
  getItem(Page.companyRegistrationAddress, CompanyRegistrationAddressStep),
  getItem(Page.companyStateRegistration, CompanyStateRegistrationStep),
  getItem(Page.organizationalAndLegalForm, OrganizationalAndLegalFormStep),
  getItem(Page.companyActivities, CompanyActivitiesStep),
  getItem(Page.companyLicense, CompanyLicenseStep),
  getItem(Page.companyStructure, CompanyStructureStep),
  getItem(Page.heads, CompanyHeadsStep),
  getItem(Page.companyConstituentDocs, CompanyConstituentDocsStep),
  getItem(Page.beneficiary, CompanyBeneficiariesStep),
  getItem(Page.companyConfirmStructureStep, CompanyConfirmStructureStep),
  getItem(Page.kyc, KycStep),
  getItem(Page.companyContacts, CompanyContactsStep, { canGoBack: false }),
  getItem(Page.branches, CompanyBranchesStep),
  getItem(
    Page.companyAccountsInOrganizations,
    CompanyAccountsInOrganizationsStep
  ),
  getItem(Page.companyFinancingSources, CompanyFinancingSourcesStep),
  getItem(Page.companyDebts, CompanyDebtsStep),
  getItem(Page.companyFinancialMonitoring, CompanyFinancialMonitoringStep),
  getItem(Page.companyBusinessRrelationship, CompanyBusinessRrelationshipStep),
  getItem(Page.withdrawalAccounts, WithdrawalAccountsStep),
  getItem(Page.orderSubmissionContacts, OrderSubmissionContactsStep),
  getItem(Page.signatureSamples, SignatureSamplesStep),
  getItem(Page.confirmationData, ConfirmationDataStep),
  getItem(Page.invoiceForPayment, InvoiceForPaymentStep, { canGoBack: false }),
  getItem(Page.invoiceConfirmForPayment, InvoiceConfirmForPaymentStep),
]);

export const roleLegalRepresentative = new Map<Page, RoutePage>([
  ...basePages,
  getItem(
    Page.legalRepresentativeCompanyName,
    LegalRepresentativeCompanyNameStep
  ),
  getItem(
    Page.legalRepresentativeCompanyTaxResidency,
    LegalRepresentativeCompanyTaxResidencyStep
  ),
  getItem(
    Page.legalRepresentativeCompanyRegistrationAddress,
    LegalRepresentativeCompanyRegistrationAddressStep
  ),
  getItem(
    Page.legalRepresentativeCompanyStateRegistration,
    LegalRepresentativeCompanyStateRegistrationStep
  ),
  getItem(
    Page.legalRepresentativeCompanyConstituentDocs,
    LegalRepresentativeCompanyConstituentDocsStep
  ),
  getItem(Page.companyName, CompanyNameStep),
  getItem(Page.companyTaxResidency, CompanyTaxResidencyStep),
  getItem(Page.companyRegistrationPlace, CompanyRegistrationPlaceStep),
  getItem(Page.companyRegistrationAddress, CompanyRegistrationAddressStep),
  getItem(Page.companyStateRegistration, CompanyStateRegistrationStep),
  getItem(Page.organizationalAndLegalForm, OrganizationalAndLegalFormStep),
  getItem(Page.companyActivities, CompanyActivitiesStep),
  getItem(Page.companyLicense, CompanyLicenseStep),
  getItem(Page.companyStructure, CompanyStructureStep),
  getItem(Page.heads, CompanyHeadsStep),
  getItem(Page.companyConstituentDocs, CompanyConstituentDocsStep),
  getItem(Page.beneficiary, CompanyBeneficiariesStep),
  getItem(Page.companyConfirmStructureStep, CompanyConfirmStructureStep),
  getItem(Page.kyc, KycStep),
  getItem(Page.companyContacts, CompanyContactsStep, { canGoBack: false }),
  getItem(Page.branches, CompanyBranchesStep),
  getItem(
    Page.companyAccountsInOrganizations,
    CompanyAccountsInOrganizationsStep
  ),
  getItem(Page.companyFinancingSources, CompanyFinancingSourcesStep),
  getItem(Page.companyDebts, CompanyDebtsStep),
  getItem(Page.companyFinancialMonitoring, CompanyFinancialMonitoringStep),
  getItem(Page.companyBusinessRrelationship, CompanyBusinessRrelationshipStep),
  getItem(Page.withdrawalAccounts, WithdrawalAccountsStep),
  getItem(Page.orderSubmissionContacts, OrderSubmissionContactsStep),
  getItem(Page.signatureSamples, SignatureSamplesStep),
  getItem(Page.confirmationData, ConfirmationDataStep),
  getItem(Page.invoiceForPayment, InvoiceForPaymentStep, { canGoBack: false }),
  getItem(Page.invoiceConfirmForPayment, InvoiceConfirmForPaymentStep),
]);
