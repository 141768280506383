import { useRef } from 'react';

import { useLegalClientLegalHeadSaveHeadTypeInfo } from 'api/requests';
import { yesOrNot } from 'helpers';
import { FileUpload, useFilesUpload } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { HeadTypeInfoFormType } from 'modules/onboarding/common/forms/headTypeInfoForm';
import { YesOrNoType } from 'types/yesOrNo';

import { useOnboardingContext } from './useOnboardingContext';

export const useHeadTypeInfo = () => {
  const { goToBack, goToNext, data } = useOnboardingContext();

  const { t } = useTranslation();

  const {
    error,
    loading: submitLoading,
    saveHeadTypeInfo,
  } = useLegalClientLegalHeadSaveHeadTypeInfo();

  const initialBody = data.legalHead.legalClientBodyId
    ? data.legalClient.bodies?.find(
        (v) => v.id === data.legalHead.legalClientBodyId
      )
    : undefined;

  const initialValues = useRef<HeadTypeInfoFormType>({
    body: initialBody
      ? { value: initialBody.id!, label: initialBody.name }
      : null,
    firstHead: yesOrNot(t, data.legalHead.isFirstHead) ?? null,
    memberConfirmationFileIds:
      data.legalHead.legalClientBodyMemberConfirmationFiles?.map((v) => v.id),
  }).current;

  const initialFiles: FileUpload[] | undefined =
    data.legalHead.legalClientBodyMemberConfirmationFiles?.map(
      (v) =>
        new FileUpload({ name: v.name, size: v.size }, undefined, false, v.id)
    );

  const {
    files,
    isLoadingFile: submitDisabled,
    onRemoveFile,
    onAddFiles,
  } = useFilesUpload(initialFiles);

  const onSubmit = async (values: HeadTypeInfoFormType) => {
    if (values) {
      await saveHeadTypeInfo(data.legalClient.id, data.legalHead.id, {
        isFirstHead: values.firstHead?.value === YesOrNoType.Yes,
        legalClientBodyId: values.body?.value!,
        legalClientBodyMemberConfirmationFileIds:
          values.memberConfirmationFileIds!,
      });
      goToNext();
    }
  };

  return {
    onSubmit,
    goToBack,
    onRemoveFile,
    onAddFiles,
    files,
    submitDisabled,
    bodies: data.legalClient.bodies,
    submitLoading,
    initialValues,
    error,
  };
};
