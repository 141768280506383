import {
  RegistrationAddressStep,
  TaxResidencyStep,
  LicenseStep,
  NameStep,
  StateRegistrationStep,
  ContactsStep,
} from './steps';
import { Page, RoutePage } from './types';

const getItem = (
  page: Page,
  component: RoutePage['component'],
  componentProps: RoutePage['componentProps'] = { canGoBack: true }
): [Page, RoutePage] => [page, { component, componentProps }];

export const pages = new Map<Page, RoutePage>([
  getItem(Page.name, NameStep, { canGoBack: false }),
  getItem(Page.taxResidency, TaxResidencyStep),
  getItem(Page.stateRegistration, RegistrationAddressStep),
  getItem(Page.stateRegistration, StateRegistrationStep),
  getItem(Page.license, LicenseStep),
  getItem(Page.registrationAddress, RegistrationAddressStep),
  getItem(Page.contacts, ContactsStep),
]);
