import { Pressed, Stack, Typography } from 'libs/ui';
import { Icon } from 'libs/ui/Icon';

import { BodyStyled } from './styles';

interface Props {
  text: string;
  onClick: () => void;
}

export const BackBody = ({ text, onClick }: Props) => (
  <BodyStyled>
    <Stack alignItems="flex-start">
      <Pressed onClick={onClick}>
        <Stack alignItems="center" direction="row" spacing={4}>
          <Icon.ArrowLeft size={20} />
          <Typography variant="bodyBold">{text}</Typography>
        </Stack>
      </Pressed>
    </Stack>
  </BodyStyled>
);
