import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientHeadSaveAddressesMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { LegalClientHeadSaveAddresses } from 'api/types/params';

import { parseLegalClientHeadEntity } from './helpers';

export const useLegalClientHeadSaveAddresses = () => {
  const [handle, { data, loading, error: apolloError }] =
    useLegalClientHeadSaveAddressesMutation();

  const error = useError(apolloError);

  return {
    data: data?.legalClientHeadSaveAddresses
      ? parseLegalClientHeadEntity(data.legalClientHeadSaveAddresses)
      : undefined,
    loading,
    error,
    saveAddresses: useCallback(
      async (
        id: string,
        headId: string,
        params: LegalClientHeadSaveAddresses
      ) => {
        const res = await handle({
          variables: {
            id,
            headId,
            input: params,
          },
        });
        return res?.data?.legalClientHeadSaveAddresses
          ? parseLegalClientHeadEntity(res.data.legalClientHeadSaveAddresses)
          : undefined;
      },
      [handle]
    ),
  };
};
