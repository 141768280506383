import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  useLegalClientRepresentativeCompleteOnboarding,
  useLegalClientRepresentativeSettingsSave,
  useOnboardingLegalClientLazy,
  useOnboardingLegalClientRepresentative,
} from 'api/requests';

import { useInnerState } from './useInnerState';
import { usePages } from './usePages';

export const useOnboarding = (clientId: string) => {
  const [mainLoading, setMainLoading] = useState(true);

  const {
    currentClient,
    loading,
    refetch: refetchCurrentClient,
  } = useOnboardingLegalClientRepresentative(clientId);

  const { saveSettings } = useLegalClientRepresentativeSettingsSave();

  const {
    getLegalClient,
    refetch: refetchLegalClient,
    legalClient,
  } = useOnboardingLegalClientLazy();

  const { completeOnboarding } =
    useLegalClientRepresentativeCompleteOnboarding();

  const pages = usePages(currentClient);

  const [routes, setRoutes] = useState(currentClient?.onboarding?.routes || []);

  const initialRoutes = useMemo(
    () => (routes.length && routes) || currentClient?.onboarding?.routes || [],
    [currentClient?.onboarding?.routes, routes]
  );

  const innerState = useInnerState(initialRoutes);

  const onChangeRoutes = useCallback(
    async (routes: string[]) => {
      if (routes && currentClient) {
        setRoutes(routes);
        await saveSettings(currentClient.id, {
          routes,
          totalRoutes: pages.size,
        });
      }
    },
    [currentClient, pages.size, saveSettings]
  );

  const onEnd = useCallback(async () => {
    if (currentClient?.id && legalClient?.id) {
      setMainLoading(true);
      try {
        await completeOnboarding(currentClient.id);
        await getLegalClient(legalClient.id);
      } finally {
        setMainLoading(false);
      }
    }
  }, [completeOnboarding, currentClient?.id, getLegalClient, legalClient?.id]);

  useEffect(() => {
    if (currentClient?.legalClientId) {
      getLegalClient(currentClient?.legalClientId).finally(() => {
        setMainLoading(false);
      });
    }
  }, [currentClient?.legalClientId, getLegalClient]);

  return {
    currentClient,
    legalClient,
    pages,
    loading: mainLoading || (!currentClient && loading),
    initialRoutes,
    innerState,
    refetchLegalClient,
    refetchCurrentClient,
    onEnd,
    onChangeRoutes,
  };
};
