import { FormContainerStepProps } from 'components';
import { useTranslation } from 'libs/i18n';
import {
  ClientType,
  FormBodyWrapper,
} from 'modules/onboarding/common/components';
import {
  BaseOnboardingRegistrationAddressFormType,
  getBaseSchema,
  FormErrors,
} from 'modules/onboarding/common/forms/onboardingRegistrationAddressForm';

import { FormContent, FormContentProps } from './FormContent';

interface Props extends FormContentProps, FormContainerStepProps {
  initialValues?: BaseOnboardingRegistrationAddressFormType;
  onSubmit: (values: BaseOnboardingRegistrationAddressFormType) => void;
  canGoBack?: boolean;
  onGoBack?: () => void;
  formError?: FormErrors;
  submitLoading?: boolean;
  clientType?: ClientType;
  subTitle?: string;
  title?: string;
}

export const RegistrationAddress = ({
  initialValues,
  canGoBack,
  onGoBack,
  submitLoading,
  formError,
  onSubmit,
  clientType,
  step,
  subTitle,
  title,
  ...formProps
}: Props) => {
  const schema = getBaseSchema();
  const { t } = useTranslation();
  return (
    <FormBodyWrapper
      canGoBack={canGoBack}
      clientType={clientType}
      initialErrors={formError}
      initialValues={initialValues}
      step={step}
      submitLoading={submitLoading}
      subTitle={subTitle ?? t('onboarding.registrationAddress.subtitle')}
      title={title ?? t('onboarding.registrationAddress.title')}
      validationSchema={schema}
      onGoBack={onGoBack}
      onSubmit={onSubmit}
    >
      <FormContent {...formProps} />
    </FormBodyWrapper>
  );
};
