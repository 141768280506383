import { useOnboardingLegalClientRepresentative } from 'api/requests';
import { useOnboardingKyc } from 'modules/onboarding/common/hooks';

import { useOnboardingContext } from './useOnboardingContext';

export const useKyc = () => {
  const { clientId, goToNext } = useOnboardingContext();

  const { currentClient, refetch } =
    useOnboardingLegalClientRepresentative(clientId);

  const { expirationHandler, loading, token } = useOnboardingKyc({
    status: currentClient?.kyc?.status,
    refetch,
    onSuccess: goToNext,
  });

  return {
    loading: loading && !token,
    token,
    status: currentClient?.kyc?.status,
    expirationHandler,
  };
};
