import { useState } from 'react';

import { useLegalClientDeleteLegalHeadFirstHead } from 'api/requests';
import { useTranslation } from 'libs/i18n';
import { useNotify } from 'libs/notify';

import { checkFirstHeadFullness } from '../helpers/checkFirstHeadFullness';

import { useOnboardingContext } from './useOnboardingContext';

export const useFirstHeads = () => {
  const [errorFirstHeadsIds, setErrorIds] = useState<string[]>();

  const { goToBack, goToNext, data } = useOnboardingContext();

  const firstHeads = data.legalHead?.firstHeads ?? [];

  const { deleteFirstHead, loading: deleteLoading } =
    useLegalClientDeleteLegalHeadFirstHead();

  const notify = useNotify();
  const { t } = useTranslation();

  const onSubmit = async () => {
    if (!firstHeads?.length) {
      notify.error(
        Error(t('onboarding.legalClientLegalHead.firstHeads.emptyErrorMessage'))
      );
      return;
    }

    const notFulled = firstHeads.filter((b) => !checkFirstHeadFullness(b));

    if (notFulled.length) {
      setErrorIds(notFulled.map((b) => b.id));

      return;
    }

    goToNext();
  };

  const onAdd = () => {
    data.onSetFirstHead();
  };

  const onEdit = (id: string) => {
    if (id) {
      data.onSetFirstHead(id);
    }
  };

  const onDelete = (id: string) => {
    if (id) {
      deleteFirstHead(data.legalClient.id!, data.legalHead.id, id);
    }
  };

  return {
    onSubmit,
    goToBack,
    onAdd,
    onEdit,
    onDelete,
    errorFirstHeadsIds,
    deleteLoading,
    firstHeads,
  };
};
