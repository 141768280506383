import { useMemo } from 'react';

import {
  DatePicker as DatePickerBase,
  LocalizationProvider,
} from '@mui/x-date-pickers/';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// eslint-disable-next-line no-restricted-imports
import { ru } from 'date-fns/locale';
import { Langs, useTranslation } from 'libs/i18n';

import { Icon } from '../Icon';
import { Input, InputProps } from '../Input';

import { DatePickerProps } from './types';

const CloseIcon = () => <Icon.Close size={16} />;

/**
 * DatePicker component.
 */
export const DatePicker = ({
  helperText,
  error,
  placeholder,
  name,
  showOptionalMark,
  ...props
}: DatePickerProps) => {
  const { language, t } = useTranslation();

  const adapterLocale = useMemo(() => {
    const locals: Record<Langs, typeof ru> = {
      [Langs.RU]: ru,
    };
    return locals[language];
  }, [language]);

  return (
    <LocalizationProvider
      adapterLocale={adapterLocale}
      dateAdapter={AdapterDateFns}
    >
      <DatePickerBase
        disableFuture
        localeText={{
          cancelButtonLabel: t('common.back'),
          toolbarTitle: t('components.datePicker.toolbarTitle'),
        }}
        slotProps={{
          openPickerButton: { disableRipple: true },
          field: { clearable: true },
          textField: {
            helperText,
            error,
            placeholder,
            name,
            showOptionalMark,
          } as InputProps,
          clearButton: { disableRipple: true, title: 'Clear' },
          openPickerIcon: { disableRipple: true },
        }}
        slots={{ textField: Input, clearIcon: CloseIcon }}
        sx={{
          "& button[title='Clear']": {
            opacity: 1,
            padding: 0,
          },
        }}
        {...props}
      />
    </LocalizationProvider>
  );
};
