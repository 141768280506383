import { useTranslation } from 'libs/i18n';
import {
  getToday,
  getTodayWithResetHours,
} from 'modules/onboarding/common/helpers/getToday';

import {
  Field,
  maxLengthLicenseNumber,
  maxLengthActivityType,
  maxLengthAuthorityName,
} from '../../../forms/companyLicenseForm';

export const Fields = () => {
  const { t } = useTranslation();
  return (
    <>
      <Field.Text
        label={t('onboarding.companyLicense.licensedActivityType.label')}
        maxLength={maxLengthActivityType}
        name="licensedActivityType"
        placeholder={t(
          'onboarding.companyLicense.licensedActivityType.placeholder'
        )}
      />
      <Field.Text
        label={t('onboarding.companyLicense.licenseNumber.label')}
        maxLength={maxLengthLicenseNumber}
        name="licenseNumber"
        placeholder={t('onboarding.companyLicense.licenseNumber.placeholder')}
      />
      <Field.DatePicker
        label={t('onboarding.companyLicense.dateOfIssue.label')}
        maxDate={getToday()}
        name="dateOfIssue"
        placeholder={t('onboarding.companyLicense.dateOfIssue.placeholder')}
      />
      <Field.DatePicker
        showOptionalMark
        disableFuture={false}
        label={t('onboarding.companyLicense.dateOfExpiry.label')}
        minDate={getTodayWithResetHours()}
        name="dateOfExpiry"
        placeholder={t('onboarding.companyLicense.dateOfExpiry.placeholder')}
      />
      <Field.Text
        label={t('onboarding.companyLicense.authorityName.label')}
        maxLength={maxLengthAuthorityName}
        name="authorityName"
        placeholder={t('onboarding.companyLicense.authorityName.placeholder')}
      />
    </>
  );
};
