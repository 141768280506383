import {
  CivilLawSubjectType,
  LegalClientRepresentativeEntity,
  RoleInCompanyType,
} from 'api/types/entity';

import {
  roleOneOfDirectors,
  roleLegalRepresentative,
  roleDirector,
} from '../../route';

export const usePages = (
  currentClient: LegalClientRepresentativeEntity | null
) => {
  if (currentClient?.roleInCompany?.role === RoleInCompanyType.OneOfDirectors) {
    return roleOneOfDirectors;
  }

  if (
    currentClient?.roleInCompany?.role ===
    RoleInCompanyType.AuthorizedRepresentative
  ) {
    if (
      currentClient?.roleInCompany.civilLawSubject ===
      CivilLawSubjectType.JuridicalPerson
    ) {
      return roleLegalRepresentative;
    }
    return roleOneOfDirectors;
  }

  return roleDirector;
};
