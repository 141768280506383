import { FC, PropsWithChildren, useEffect, useState } from 'react';

import { LoadingViewWrapper } from 'components';
import { ROUTES, useNavigate } from 'libs/navigation';
import { useUser } from 'libs/providers';

export const NavigationWrapper: FC<PropsWithChildren> = ({ children }) => {
  const { user } = useUser();
  const { navigate, paths } = useNavigate();

  const isLoginPage =
    paths.includes(ROUTES.login.fullPath) ||
    paths.includes(ROUTES.loginByToken.fullPath);

  const isRegistrationPage = paths.includes(ROUTES.registration.fullPath);

  const isOnboarding = paths.some((v) =>
    v.startsWith(ROUTES.onboarding.fullPath)
  );

  const isRestorePassword = paths.includes(ROUTES.restorePassword.fullPath);
  const isAuthPage = isLoginPage || isRegistrationPage || isRestorePassword;

  const isTempPassword = user?.isPasswordUpdateRequired;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user) {
      if (!user.otp?.verified || isTempPassword) {
        navigate(ROUTES.registration.fullPath, {});
      } else if (!isOnboarding) {
        // TODO: add check onboarding status
        navigate(ROUTES.onboarding.fullPath, {});
      }
    } else if (!isAuthPage) {
      navigate(ROUTES.login.fullPath, {});
    }
    setLoading(false);
  }, [navigate, user, isAuthPage, isOnboarding, isTempPassword]);

  return <LoadingViewWrapper loading={loading}>{children}</LoadingViewWrapper>;
};
