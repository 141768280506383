// eslint-disable-next-line no-restricted-imports
import { LegalClientHeadFragment } from 'api/generated/graphql';
import { LegalClientHeadEntity } from 'api/types/entity';
import { lodash } from 'helpers';

import {
  parseCountryEntity,
  parseKyc,
  parsePepConnectionEntity,
  parseTaxResidenceEntity,
} from '../../../helpers';
import { parseLegalClientOnboardingStatus } from '../../helpers/parseLegalClientOnboardingStatus';
import { parsePositionInCompanyEntity } from '../../helpers/parsePositionInCompanyEntity';

export const parseLegalClientHeadEntity = (
  data: LegalClientHeadFragment
): LegalClientHeadEntity => ({
  id: data.id,
  createdAt: new Date(data.createdAt),
  firstName: data.firstName,
  lastName: data.lastName,
  email: data.email,
  middleName: data.middleName || undefined,
  dateOfBirth: new Date(data.dateOfBirth),
  isFirstHead: data.isFirstHead ?? undefined,
  legalClientId: data.legalClientId || undefined,
  onboarding: {
    routes: lodash.compact(data.onboarding?.routes),
  },
  legalClientBodyId: data.legalClientBodyId ?? undefined,

  legalClientOnboardingStatus: data.legalClientOnboardingStatus
    ? parseLegalClientOnboardingStatus(data.legalClientOnboardingStatus)
    : undefined,

  citizenships: data.citizenships
    ? lodash.compact(data.citizenships).map(parseCountryEntity)
    : undefined,
  taxResidences: data.taxResidences
    ? lodash.compact(data.taxResidences).map(parseTaxResidenceEntity)
    : undefined,
  registrationAddress: data.registrationAddress,
  residentialAddress: data.residentialAddress,
  pepConnection: parsePepConnectionEntity(data),
  positionInCompany: data.positionInCompany
    ? parsePositionInCompanyEntity(data.positionInCompany)
    : undefined,

  kyc: parseKyc(data.kyc),
});
