import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';

import {
  useLegalClientBeneficiarySettingsSave,
  useLegalClientCompleteBeneficiaryOnboarding,
  useLegalClientCreateBeneficiary,
  useLegalClientGetBeneficiaryLazy,
} from 'api/requests/onboarding/legal/beneficiary';
import {
  LegalClientEntity,
  LegalClientRepresentativeEntity,
} from 'api/types/entity';
import { PersonalDataFormType } from 'modules/onboarding/common/forms/personalDataForm';

import { pages } from '../route';

export const useOnboarding = (
  currentClient: LegalClientRepresentativeEntity,
  legalClient: LegalClientEntity,
  legalClientBeneficiaryId?: string
) => {
  const beneficiaryId = useRef(legalClientBeneficiaryId);
  const [loading, setLoading] = useState(false);

  const { saveSettings } = useLegalClientBeneficiarySettingsSave();

  const { getBeneficiary, legalClientBeneficiary } =
    useLegalClientGetBeneficiaryLazy();

  const { complete } = useLegalClientCompleteBeneficiaryOnboarding();

  const { createBeneficiary } = useLegalClientCreateBeneficiary();

  const initialRoutes = useMemo(() => {
    const routes = legalClientBeneficiary?.onboarding?.routes ?? [];
    return routes;
  }, [legalClientBeneficiary?.onboarding?.routes]);

  const handleCreateBeneficiary = async (
    personalData: PersonalDataFormType
  ) => {
    const beneficiary = await createBeneficiary(legalClient.id, personalData);
    if (beneficiary) {
      await getBeneficiary(legalClient.id, beneficiary.id);
    }
    return beneficiary;
  };

  const onChangeRoutes = useCallback(
    async (routes: string[]) => {
      if (routes && legalClientBeneficiaryId) {
        await saveSettings(legalClient.id, legalClientBeneficiaryId, {
          routes,
          totalRoutes: pages.size,
        });
      }
    },
    [legalClient.id, legalClientBeneficiaryId, saveSettings]
  );

  useLayoutEffect(() => {
    if (beneficiaryId.current) {
      setLoading(true);
      getBeneficiary(legalClient.id, beneficiaryId.current).finally(() =>
        setLoading(false)
      );
    }
  }, [getBeneficiary, legalClient.id]);

  const onComplete = async () =>
    complete(legalClient.id, legalClientBeneficiaryId!);

  return {
    currentClient,
    legalClientBeneficiary,
    legalClient,
    loading,
    initialRoutes,
    onChangeRoutes,
    onComplete,
    createBeneficiary: handleCreateBeneficiary,
  };
};
