import {
  LegalClientEntity,
  LegalClientRepresentativeEntity,
} from 'api/types/entity';
import { LoadingLayout } from 'components';
import { useTranslation } from 'libs/i18n';
import { Stack } from 'libs/ui';
import {
  BackBody,
  OnboardingLayout,
} from 'modules/onboarding/common/components';
import { PersonalDataFormType } from 'modules/onboarding/common/forms/personalDataForm';
import { OnboardingProvider } from 'modules/onboarding/common/providers';

import { useOnboarding } from '../hooks/useOnboarding';
import { pages } from '../route';

interface Props {
  currentClient: LegalClientRepresentativeEntity;
  legalClient: LegalClientEntity;
  legalHeadId: string;
  id?: string;
  goBack: () => void;
  onCreateFirstHead: (id: string) => void;
}

export const LegalClientLegalHeadFirstHeadContainer = ({
  id,
  currentClient,
  legalClient,
  legalHeadId,
  goBack,
  onCreateFirstHead,
}: Props) => {
  const {
    loading,
    initialRoutes,
    firstHead,
    createFirstHead,
    onChangeRoutes,
    onComplete,
  } = useOnboarding(currentClient, legalClient, legalHeadId, id);

  const { t } = useTranslation();

  if (loading) {
    return <LoadingLayout />;
  }

  const handleEnd = async () => {
    if (await onComplete()) {
      goBack();
    }
  };

  const handleCreateFirstHead = async (personalData: PersonalDataFormType) => {
    const firstHead = await createFirstHead(personalData);
    if (firstHead) {
      onCreateFirstHead(firstHead.id);
    }
    return !!firstHead;
  };

  return (
    <OnboardingProvider
      clientId={currentClient.id}
      data={{
        currentClient,
        legalClient,
        legalHeadId,
        firstHead,
        onCreateFirstHead: handleCreateFirstHead,
      }}
      initialRoutes={initialRoutes}
      pages={pages}
      renderChild={({ route }) => {
        const Component = route.page.component;
        return (
          <OnboardingLayout key={route.pageKey} headerComponent={<div />}>
            <Stack spacing={16}>
              <BackBody
                text={t('onboarding.legalClientFirstHead.backTitle')}
                onClick={goBack}
              />
              <Component {...route.page.componentProps} />
            </Stack>
          </OnboardingLayout>
        );
      }}
      onChangeRoutes={onChangeRoutes}
      onEnd={handleEnd}
    />
  );
};
