import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useClientRepresentativeSaveAddressesMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { AddressesParam } from 'api/types/params/onboarding/addressesParam';

import { parseClientRepresentative } from './helpers/parseClientRepresentative';

export const useClientRepresentativeSaveAddresses = () => {
  const [handle, { data, loading, error: apolloError }] =
    useClientRepresentativeSaveAddressesMutation();

  const error = useError(apolloError);

  return {
    data: data?.clientRepresentativeSaveAddresses
      ? parseClientRepresentative(data.clientRepresentativeSaveAddresses)
      : undefined,
    loading,
    error,
    saveAddresses: useCallback(
      async (id: string, params: AddressesParam) => {
        const res = await handle({
          variables: {
            id,
            input: params,
          },
        });
        return res?.data?.clientRepresentativeSaveAddresses
          ? parseClientRepresentative(
              res.data.clientRepresentativeSaveAddresses
            )
          : undefined;
      },
      [handle]
    ),
  };
};
