import { useRef } from 'react';

import { useLegalClientBranchSaveLicenseInfo } from 'api/requests/onboarding/legal/branch';
import { useServerErrorNotify } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { useOnboardingCountries } from 'modules/onboarding/common/hooks';
import { getBaseInitialData } from 'modules/onboarding/legal/helpers/companyLicense';

import { LicenseFormType } from '../forms/licenseForm';
import { getSubmitData } from '../helpers/license';

import { useOnboardingContext } from './useOnboardingContext';

export const useLicense = () => {
  const { goToBack, goToNext, data } = useOnboardingContext();

  const { error, loading, saveAddresses } =
    useLegalClientBranchSaveLicenseInfo();

  const { countries, loading: countriesLoading } = useOnboardingCountries();

  const { t } = useTranslation();

  const initialValues = useRef(
    getBaseInitialData(data.legalClientBranch, t)
  ).current;

  const onSubmit = async (values: LicenseFormType) => {
    if (values) {
      const submitData = getSubmitData(values);

      await saveAddresses(
        data.legalClient.id,
        data.legalClientBranch.id,
        submitData
      );
      goToNext();
    }
  };

  useServerErrorNotify(error);

  return {
    onSubmit,
    goToBack,
    submitLoading: loading,
    initialValues,
    countries,
    countriesLoading,
  };
};
