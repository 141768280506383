import { getYesOrNoOptions } from 'helpers';
import { FileUpload } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { FileType } from 'libs/ui';
import { YesOrNoType } from 'types/yesOrNo';

import {
  useFormContext,
  Field,
  MAX_FILES,
} from '../../../forms/companyLicenseForm';

import { Fields } from './Fields';

export interface FormContentProps {
  files?: FileUpload[];
  onAddFiles?: (file: File[]) => void;
  onRemoveFile?: (file: FileUpload) => void;
}

export const FormContent = ({
  files,
  onAddFiles,
  onRemoveFile,
}: FormContentProps) => {
  const { t } = useTranslation();
  const { values } = useFormContext();
  return (
    <>
      <Field.Autocomplete
        label={t('onboarding.companyLicense.companyActivityIsLicensed.label')}
        name="companyActivityIsLicensed"
        optionLabelKey="label"
        options={getYesOrNoOptions(t)}
        optionValueKey="value"
        placeholder={t('common.select.placeholder')}
        variant="select"
      />
      {values.companyActivityIsLicensed &&
        values.companyActivityIsLicensed.value === YesOrNoType.Yes && (
          <>
            <Fields />

            <Field.FilesUpload
              files={files}
              fileTypes={[FileType.pdf]}
              maxFiles={MAX_FILES}
              name="fileIds"
              title={t('onboarding.companyLicense.documentTitle')}
              onAddFiles={(f) => onAddFiles?.(f)}
              onRemoveFile={onRemoveFile}
            />
          </>
        )}
    </>
  );
};
