import { useRef } from 'react';

import { useLegalClientLegalHeadFirstHeadSaveAddresses } from 'api/requests';
import { useServerErrorNotify } from 'hooks';
import { BaseOnboardingRegistrationAddressFormType } from 'modules/onboarding/common/forms/onboardingRegistrationAddressForm';
import {
  getBaseInitialData,
  getBaseSubmitData,
} from 'modules/onboarding/common/helpers/registrationAddress';
import { useOnboardingCountries } from 'modules/onboarding/common/hooks';

import { useOnboardingContext } from './useOnboardingContext';

export const useRegistrationAddress = () => {
  const { goToBack, goToNext, data } = useOnboardingContext();

  const { countries, loading: countriesLoading } = useOnboardingCountries();
  const { error, loading, saveAddresses } =
    useLegalClientLegalHeadFirstHeadSaveAddresses();

  const initialValues = useRef(
    data.firstHead.registrationAddress
      ? getBaseInitialData(
          data.firstHead.registrationAddress,
          data.firstHead.residentialAddress
        )
      : undefined
  ).current;

  const onSubmit = async (
    values: BaseOnboardingRegistrationAddressFormType
  ) => {
    if (values) {
      const submitData = getBaseSubmitData(values);
      await saveAddresses(
        data.legalClient.id,
        data.legalHeadId,
        data.firstHead.id,
        submitData
      );
      goToNext();
    }
  };

  useServerErrorNotify(error);

  return {
    initialValues,
    countries,
    countriesLoading,
    submitLoading: loading,
    onSubmit,
    goToBack,
  };
};
