import { SvgIcon } from '../SvgIcon';
import { SvgProps } from '../types';

export const Sun = ({ size = 19, className }: SvgProps) => (
  <SvgIcon className={className} size={size}>
    <svg
      fill="none"
      height="20"
      viewBox="0 0 19 20"
      width="19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5836_20701)">
        <path
          clipRule="evenodd"
          d="M9.521 4.89931C8.5066 4.89881 7.51483 5.19919 6.67117 5.76245C5.82752 6.32571 5.16988 7.12654 4.78145 8.06363C4.39302 9.00073 4.29126 10.032 4.48903 11.0269C4.68681 12.0219 5.17524 12.9358 5.89254 13.6531C6.60983 14.3704 7.52376 14.8588 8.5187 15.0566C9.51364 15.2544 10.5449 15.1526 11.482 14.7642C12.4191 14.3757 13.2199 13.7181 13.7832 12.8744C14.3464 12.0308 14.6468 11.039 14.6463 10.0246C14.6443 8.66591 14.1037 7.36344 13.1429 6.4027C12.1822 5.44195 10.8797 4.90132 9.521 4.89931ZM9.521 13.6877C8.7964 13.6882 8.08793 13.4738 7.4852 13.0716C6.88247 12.6694 6.41257 12.0975 6.13493 11.4282C5.85729 10.7589 5.78438 10.0223 5.92544 9.31153C6.06649 8.60079 6.41517 7.94785 6.92736 7.4353C7.43955 6.92275 8.09225 6.57362 8.80289 6.43208C9.51354 6.29053 10.2502 6.36292 10.9197 6.6401C11.5892 6.91728 12.1614 7.38678 12.564 7.98923C12.9667 8.59168 13.1816 9.30001 13.1816 10.0246C13.1832 10.5065 13.0898 10.984 12.9066 11.4297C12.7234 11.8755 12.4541 12.2807 12.1141 12.6221C11.774 12.9636 11.3699 13.2346 10.925 13.4196C10.48 13.6047 10.0029 13.7001 9.521 13.7004V13.6877Z"
          fill="#F6F6F7"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M9.52016 0.5C9.42394 0.499999 9.32866 0.518994 9.2398 0.555893C9.15093 0.592793 9.07022 0.646872 9.0023 0.71503C8.93438 0.783188 8.88058 0.864084 8.84399 0.953078C8.8074 1.04207 8.78873 1.13741 8.78907 1.23364V2.69837C8.80007 2.88515 8.88202 3.06067 9.01816 3.18903C9.15429 3.31738 9.33433 3.38887 9.52143 3.38887C9.70854 3.38887 9.88857 3.31738 10.0247 3.18903C10.1608 3.06067 10.2428 2.88515 10.2538 2.69837V1.23871C10.2545 1.14194 10.236 1.046 10.1994 0.956404C10.1629 0.866808 10.1089 0.785332 10.0407 0.716669C9.97254 0.648006 9.89143 0.593512 9.80209 0.556326C9.71275 0.519141 9.61694 0.499998 9.52016 0.5Z"
          fill="#F6F6F7"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M9.52016 16.6147C9.42394 16.6147 9.32866 16.6337 9.2398 16.6706C9.15093 16.7075 9.07022 16.7616 9.0023 16.8297C8.93438 16.8979 8.88058 16.9788 8.84399 17.0678C8.8074 17.1568 8.78873 17.2521 8.78907 17.3484V18.8131C8.80007 18.9999 8.88202 19.1754 9.01816 19.3037C9.15429 19.4321 9.33433 19.5036 9.52143 19.5036C9.70854 19.5036 9.88857 19.4321 10.0247 19.3037C10.1608 19.1754 10.2428 18.9999 10.2538 18.8131V17.3484C10.2538 17.1538 10.1765 16.9672 10.0389 16.8296C9.90134 16.692 9.71474 16.6147 9.52016 16.6147Z"
          fill="#F6F6F7"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M16.2522 3.29222C16.1148 3.15595 15.9291 3.0795 15.7356 3.0795C15.5421 3.0795 15.3564 3.15595 15.219 3.29222L14.1833 4.32794C14.0459 4.46528 13.9688 4.65156 13.9688 4.8458C13.9688 5.04004 14.0459 5.22632 14.1833 5.36366C14.3206 5.50101 14.5069 5.57817 14.7011 5.57817C14.8954 5.57817 15.0816 5.50101 15.219 5.36366L16.2522 4.32794C16.3203 4.26002 16.3744 4.17931 16.4113 4.09044C16.4482 4.00158 16.4672 3.9063 16.4672 3.81008C16.4672 3.71386 16.4482 3.61858 16.4113 3.52971C16.3744 3.44085 16.3203 3.36014 16.2522 3.29222Z"
          fill="#F6F6F7"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M4.86054 14.6853C4.72303 14.5483 4.53682 14.4713 4.34268 14.4713C4.14855 14.4713 3.96233 14.5483 3.82482 14.6853L2.7891 15.7312C2.68165 15.8729 2.62956 16.049 2.64259 16.2264C2.65562 16.4038 2.73289 16.5704 2.8599 16.6949C2.98692 16.8194 3.15498 16.8934 3.3326 16.9029C3.51022 16.9124 3.68522 16.8568 3.82482 16.7466L4.86054 15.7312C4.92962 15.6627 4.98445 15.5813 5.02187 15.4915C5.05929 15.4018 5.07856 15.3055 5.07856 15.2082C5.07856 15.111 5.05929 15.0147 5.02187 14.925C4.98445 14.8352 4.92962 14.7538 4.86054 14.6853Z"
          fill="#F6F6F7"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M0 10.0245C0 10.2191 0.0772936 10.4057 0.214877 10.5433C0.352461 10.6809 0.539064 10.7581 0.733636 10.7581H2.19837C2.38515 10.7471 2.56067 10.6652 2.68903 10.5291C2.81738 10.3929 2.88887 10.2129 2.88887 10.0258C2.88887 9.83868 2.81738 9.65864 2.68903 9.52251C2.56067 9.38637 2.38515 9.30442 2.19837 9.29342H0.733636C0.637414 9.29308 0.542072 9.31175 0.453078 9.34834C0.364084 9.38493 0.283188 9.43873 0.21503 9.50665C0.146872 9.57457 0.0927931 9.65528 0.0558933 9.74415C0.0189936 9.83301 -5.80037e-07 9.92829 0 10.0245Z"
          fill="#F6F6F7"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M16.1094 10.0245C16.1094 10.2191 16.1867 10.4057 16.3243 10.5433C16.4618 10.6809 16.6484 10.7581 16.843 10.7581H18.3077C18.4945 10.7471 18.67 10.6652 18.7984 10.5291C18.9268 10.3929 18.9982 10.2129 18.9982 10.0258C18.9982 9.83867 18.9268 9.65864 18.7984 9.5225C18.67 9.38636 18.4945 9.30441 18.3077 9.29341H16.843C16.6489 9.29341 16.4627 9.37035 16.3251 9.50739C16.1876 9.64442 16.11 9.83038 16.1094 10.0245Z"
          fill="#F6F6F7"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M2.78325 3.29226C2.64683 3.43009 2.57031 3.61619 2.57031 3.81012C2.57031 4.00405 2.64683 4.19014 2.78325 4.32798L3.81898 5.36116C3.88682 5.42917 3.96739 5.48315 4.05609 5.52001C4.14479 5.55688 4.23988 5.57592 4.33594 5.57604C4.432 5.57616 4.52714 5.55735 4.61593 5.5207C4.70472 5.48405 4.78542 5.43027 4.85343 5.36243C4.92143 5.29459 4.97541 5.21402 5.01228 5.12532C5.04915 5.03662 5.06819 4.94153 5.0683 4.84547C5.06842 4.74941 5.04962 4.65427 5.01297 4.56548C4.97632 4.47669 4.92254 4.39598 4.8547 4.32798L3.81898 3.29226C3.75105 3.2241 3.67035 3.17002 3.58148 3.13312C3.49261 3.09622 3.39734 3.07722 3.30111 3.07722C3.20489 3.07722 3.10962 3.09622 3.02075 3.13312C2.93188 3.17002 2.85118 3.2241 2.78325 3.29226Z"
          fill="#F6F6F7"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M14.1827 14.6853C14.0457 14.8228 13.9688 15.009 13.9688 15.2031C13.9688 15.3972 14.0457 15.5835 14.1827 15.721L15.2185 16.7465C15.358 16.8568 15.533 16.9123 15.7107 16.9028C15.8883 16.8933 16.0564 16.8194 16.1834 16.6948C16.3104 16.5703 16.3876 16.4037 16.4007 16.2263C16.4137 16.0489 16.3616 15.8729 16.2542 15.7311L15.2185 14.6954C15.1512 14.6266 15.071 14.5717 14.9825 14.5339C14.894 14.4962 14.7989 14.4762 14.7027 14.4753C14.6065 14.4743 14.511 14.4924 14.4218 14.5284C14.3326 14.5645 14.2513 14.6178 14.1827 14.6853Z"
          fill="#F6F6F7"
          fillRule="evenodd"
        />
        <circle cx="9.5" cy="10" fill="#F6F6F7" r="4.5" />
      </g>
      <defs>
        <clipPath id="clip0_5836_20701">
          <rect
            fill="white"
            height="19"
            transform="translate(0 0.5)"
            width="19"
          />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);
