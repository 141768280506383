import { getColor } from 'helpers';
import { CSSObject } from 'libs/ui/styled';
import { ColorsLight, ColorsDark, Theme } from 'libs/ui/theme';

import { ButtonColorVariant } from '../types';

const getColorPrimary = (theme: Theme) => ({
  textColor: getColor(theme, {
    dark: ColorsDark.white,
    light: ColorsLight.black,
  }),

  disableTextColor: getColor(theme, {
    dark: ColorsDark.gray250,
    light: ColorsLight.gray250,
  }),
});

const getColorRed = (theme: Theme) => ({
  textColor: getColor(theme, {
    dark: ColorsDark.red100,
    light: ColorsLight.red100,
  }),

  disableTextColor: getColor(theme, {
    dark: ColorsDark.gray250,
    light: ColorsLight.gray250,
  }),
});

export const getTextMode = (
  theme: Theme,
  colorVariant: ButtonColorVariant
): CSSObject => {
  const colors =
    colorVariant === 'primary' ? getColorPrimary(theme) : getColorRed(theme);
  return {
    [theme.breakpoints.up('mobile')]: {
      ...theme.typography.bodyBold,
    },
    [theme.breakpoints.down('mobile')]: {
      ...theme.typography.captionBold,
    },

    height: '34px',
    paddingLeft: '8px',
    paddingRight: '8px',
    color: colors.textColor,

    '&': {
      [theme.breakpoints.down('mobile')]: {
        height: 30,
      },
    },

    '&:hover': {
      background: 'none',
    } as CSSObject,

    '&:active': {
      background: 'none',
    } as CSSObject,

    '&:disabled': {
      background: 'none',
      color: colors.disableTextColor,
    } as CSSObject,
  };
};
