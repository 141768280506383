import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';

import {
  useLegalClientBranchSettingsSave,
  useLegalClientCompleteBranchOnboarding,
  useLegalClientCreateBranch,
  useLegalClientGetBranchLazy,
} from 'api/requests/onboarding/legal/branch';
import {
  LegalClientEntity,
  LegalClientRepresentativeEntity,
} from 'api/types/entity';

import { NameFormType } from '../forms/nameForm';
import { pages } from '../route';

export const useOnboarding = (
  currentClient: LegalClientRepresentativeEntity,
  legalClient: LegalClientEntity,
  legalClientBranchId?: string
) => {
  const branchId = useRef(legalClientBranchId);
  const [loading, setLoading] = useState(false);

  const { saveSettings } = useLegalClientBranchSettingsSave();
  const { getBranch, legalClientBranch } = useLegalClientGetBranchLazy();

  const { complete } = useLegalClientCompleteBranchOnboarding();

  const { createBranch } = useLegalClientCreateBranch();

  const initialRoutes = useMemo(() => {
    const routes = legalClientBranch?.onboarding?.routes ?? [];
    return routes;
  }, [legalClientBranch?.onboarding?.routes]);

  const handleCreateBranch = async (name: NameFormType) => {
    const branch = await createBranch(legalClient.id, name);
    if (branch) {
      await getBranch(legalClient.id, branch.id);
    }
    return branch;
  };

  const onChangeRoutes = useCallback(
    async (routes: string[]) => {
      if (routes && legalClientBranchId) {
        await saveSettings(legalClient.id, legalClientBranchId, {
          routes,
          totalRoutes: pages.size,
        });
      }
    },
    [legalClient.id, legalClientBranchId, saveSettings]
  );

  useLayoutEffect(() => {
    if (branchId.current) {
      setLoading(true);
      getBranch(legalClient.id, branchId.current).finally(() =>
        setLoading(false)
      );
    }
  }, [getBranch, legalClient.id]);

  const onComplete = async () => complete(legalClient.id, legalClientBranchId!);

  return {
    currentClient,
    legalClientBranch,
    legalClient,
    loading,
    initialRoutes,
    createBranch: handleCreateBranch,
    onChangeRoutes,
    onComplete,
  };
};
