import { LegalClientBranchSaveLicenseInfoParam } from 'api/types/params';
import { BaseLicenseFormType } from 'modules/onboarding/legal/forms/companyLicenseForm';
import { getBaseSubmitData } from 'modules/onboarding/legal/helpers/companyLicense';
import { YesOrNoType } from 'types/yesOrNo';

export const getSubmitData = (
  values: BaseLicenseFormType
): LegalClientBranchSaveLicenseInfoParam => {
  const isLicensed =
    values.companyActivityIsLicensed?.value === YesOrNoType.Yes;

  if (!isLicensed) {
    return { isLicensed };
  }

  return getBaseSubmitData(values, isLicensed);
};
