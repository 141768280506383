import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';

import {
  useLegalClientCompleteHeadOnboarding,
  useLegalClientGetHeadLazy,
  useLegalClientHeadSettingsSave,
} from 'api/requests';
import {
  LegalClientEntity,
  LegalClientRepresentativeEntity,
} from 'api/types/entity';
import { LegalClientCreateHeadSaveParam } from 'api/types/params';
import { OnboardingOnChangeRoutesFunc } from 'modules/onboarding/common/providers';

import { pages } from '../route';
import { LegalClientHeadPage } from '../types';

import { useCreateHead } from './useCreateHead';

export const useOnboarding = (
  currentClient: LegalClientRepresentativeEntity,
  legalClient: LegalClientEntity,
  legalClientHeadId?: string
) => {
  const headId = useRef(legalClientHeadId);
  const { saveSettings } = useLegalClientHeadSettingsSave();

  const { complete } = useLegalClientCompleteHeadOnboarding();

  const { getHead, legalClientHead } = useLegalClientGetHeadLazy();

  const { createHead } = useCreateHead(legalClient);

  const [loading, setLoading] = useState(false);

  const onChangeRoutes = useCallback<OnboardingOnChangeRoutesFunc>(
    async (routes: string[], { currentPage }, type) => {
      if (routes && legalClient && legalClientHeadId) {
        // complete when the organ has been restored
        if (
          type === 'next' &&
          currentPage === LegalClientHeadPage.headTypeInfo &&
          legalClientHead?.positionInCompany?.position
        ) {
          complete(legalClient.id, legalClientHeadId);
        }

        await saveSettings(legalClient.id, legalClientHeadId, {
          routes,
          totalRoutes: pages.size,
        });
      }
    },
    [
      complete,
      legalClient,
      legalClientHead?.positionInCompany?.position,
      legalClientHeadId,
      saveSettings,
    ]
  );

  const initialRoutes = useMemo(
    () => legalClientHead?.onboarding?.routes || [],
    [legalClientHead?.onboarding?.routes]
  );

  const onComplete = async () => complete(legalClient.id, legalClientHead?.id!);

  const handleCreateHead = async (param: LegalClientCreateHeadSaveParam) => {
    const head = await createHead(param);
    if (head) {
      await getHead(legalClient.id, head.id);
    }
    return head;
  };

  useLayoutEffect(() => {
    if (headId.current) {
      setLoading(true);
      getHead(legalClient.id, headId.current).finally(() => setLoading(false));
    }
  }, [getHead, legalClient.id]);

  return {
    currentClient,
    legalClientHead,
    legalClient,
    loading,
    initialRoutes,
    onChangeRoutes,
    onComplete,
    createHead: handleCreateHead,
  };
};
