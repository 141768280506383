import { Layout } from 'components';
import { CSSObject, styled } from 'libs/ui/styled';

export const ContainerStyled = styled(Layout)(({ theme }) => ({
  [theme.breakpoints.up('mobile')]: {
    height: '100%',
  },
  '& .layout-content': {
    overflowY: 'auto',
    overflowX: 'hidden',
    [theme.breakpoints.up('mobile')]: {
      overflowY: 'hidden',
      alignSelf: 'center',
    },
    marginTop: 48,
    [theme.breakpoints.down('mobile')]: {
      marginTop: 32,
      paddingBottom: 26,
    } as CSSObject,
    maxWidth: 1222,
  } as CSSObject,

  '.agreement-body': {
    [theme.breakpoints.up('mobile')]: {
      overflowY: 'hidden',
    },
    margin: '0 auto',
    paddingRight: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 32,
    height: '100%',

    '.agreement-content': {
      [theme.breakpoints.up('mobile')]: {
        overflowY: 'hidden',
      },

      '.agreement-contentWrapper': {
        [theme.breakpoints.up('mobile')]: {
          overflowY: 'hidden',
        },
      },

      '.agreement-textBox': {
        overflowY: 'auto',
        paddingRight: 12,

        '.agreement-text': {
          whiteSpace: 'pre-line',
        },
      },
    },
  } as CSSObject,

  '.agreement-buttons': {
    [theme.breakpoints.down('mobile')]: {
      position: 'fixed',
      bottom: 0,
      paddingBottom: 26,
      paddingTop: 26,
      paddingRight: 16,
      width: '100%',
      right: 0,
      gap: 16,
      backgroundColor: theme.palette.body,
    } as CSSObject,
  },
}));
