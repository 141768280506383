import { License } from '../components/License/License';
import { useLicense, useOnboardingContext } from '../hooks';
import { PageComponentProps } from '../types';

export const LicenseStep = ({ canGoBack }: PageComponentProps) => {
  const { initialValues, onSubmit, submitLoading, goToBack } = useLicense();

  const { step } = useOnboardingContext();

  return (
    <License
      canGoBack={canGoBack}
      initialValues={initialValues}
      step={step}
      submitLoading={submitLoading}
      onGoBack={goToBack}
      onSubmit={onSubmit}
    />
  );
};
