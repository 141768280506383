import { VerificationCodeFormType } from 'components';
import { useTranslation } from 'libs/i18n';
import { ROUTES, useNavigate, usePageTitle } from 'libs/navigation';

import { LoginFormType } from '../forms/loginFormForm';
import { useLogin } from '../hooks';
import { LoginView } from '../views/LoginView/LoginView';

export const LoginContainer = () => {
  const { navigate } = useNavigate();

  const {
    formError,
    onSubmit,
    loading,
    isOpenVerificationDialog,
    verificationFormError,
    onSubmitVerification,
    onCloseVerificationDialog,
  } = useLogin();

  const { t } = useTranslation();

  const goToMainPage = () => {
    window.location.replace('/');
  };

  const handleSubmit = async (values: LoginFormType) => {
    if (await onSubmit(values)) {
      goToMainPage();
    }
  };
  const handleSubmitVerification = async (values: VerificationCodeFormType) => {
    if (await onSubmitVerification(values)) {
      goToMainPage();
    }
  };

  const onClickRegistration = () => {
    navigate(ROUTES.registration.fullPath, {});
  };

  usePageTitle(t('auth.login.pageTitle'));

  return (
    <LoginView
      formError={formError}
      isOpenVerificationDialog={isOpenVerificationDialog}
      loading={loading}
      verificationFormError={verificationFormError}
      onClickRegistration={onClickRegistration}
      onCloseVerificationDialog={onCloseVerificationDialog}
      onSubmit={handleSubmit}
      onSubmitVerification={handleSubmitVerification}
    />
  );
};
