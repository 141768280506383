import { CompanyLicense } from '../components';
import { useCompanyLicense } from '../hooks';
import { PageComponentProps } from '../types';

export const CompanyLicenseStep = ({ canGoBack }: PageComponentProps) => {
  const {
    files,
    initialValues,
    onAddFiles,
    onRemoveFile,
    onSubmit,
    submitDisabled,
    submitLoading,
    goToBack,
  } = useCompanyLicense();

  return (
    <CompanyLicense
      canGoBack={canGoBack}
      files={files}
      initialValues={initialValues}
      submitDisabled={submitDisabled}
      submitLoading={submitLoading}
      onAddFiles={onAddFiles}
      onGoBack={goToBack}
      onRemoveFile={onRemoveFile}
      onSubmit={onSubmit}
    />
  );
};
