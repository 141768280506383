import { Body, FormLayout } from 'components';
import { styled } from 'libs/ui/styled';

export const LayoutStyled = styled(FormLayout)(({ theme }) => ({
  '.formLayout-content': {
    maxWidth: 951,

    [theme.breakpoints.up('mobile')]: {
      marginTop: 'auto',
    },
  },
}));

export const BodyStyled = styled(Body)(({ theme }) => ({
  padding: 92,
  [theme.breakpoints.down('tablet')]: {
    padding: 54,
  },
  [theme.breakpoints.down('mobile')]: {
    padding: 0,
    background: 'none',
  },

  '.registration-onboarding-button': {
    alignItems: 'flex-end',
    [theme.breakpoints.down('tablet')]: {
      alignItems: 'normal',
    },
  },

  '.registration-onboarding-content': {
    [theme.breakpoints.down('tablet')]: {
      gap: 20,
    },
  },
}));
