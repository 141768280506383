import {
  LegalClientEntity,
  LegalClientRepresentativeEntity,
} from 'api/types/entity';
import { LoadingLayout } from 'components';
import { useTranslation } from 'libs/i18n';
import { Stack } from 'libs/ui';
import {
  BackBody,
  OnboardingLayout,
} from 'modules/onboarding/common/components';
import { OnboardingProvider } from 'modules/onboarding/common/providers';

import { NameFormType } from '../forms/nameForm';
import { useOnboarding } from '../hooks/useOnboarding';
import { pages } from '../route';

interface Props {
  currentClient: LegalClientRepresentativeEntity;
  legalClient: LegalClientEntity;
  id?: string;
  goBack: () => void;
  onCreateBranch: (id: string) => void;
}

export const LegalClientBranchContainer = ({
  id,
  currentClient,
  legalClient,
  goBack,
  onCreateBranch,
}: Props) => {
  const {
    loading,
    initialRoutes,
    legalClientBranch,
    createBranch,
    onChangeRoutes,
    onComplete,
  } = useOnboarding(currentClient, legalClient, id);

  const { t } = useTranslation();

  if (loading) {
    return <LoadingLayout />;
  }

  const handleEnd = async () => {
    if (await onComplete()) {
      goBack();
    }
  };

  const handleCreateBranch = async (name: NameFormType) => {
    const branch = await createBranch(name);
    if (branch) {
      onCreateBranch(branch.id);
    }
    return !!branch;
  };

  return (
    <OnboardingProvider
      clientId={currentClient.id}
      data={{
        currentClient,
        legalClient,
        legalClientBranch,
        onCreateBranch: handleCreateBranch,
      }}
      initialRoutes={initialRoutes}
      pages={pages}
      renderChild={({ route }) => {
        const Component = route.page.component;
        return (
          <OnboardingLayout key={route.pageKey} headerComponent={<div />}>
            <Stack spacing={16}>
              <BackBody
                text={t('onboarding.legalClientBranch.backTitle')}
                onClick={goBack}
              />
              <Component {...route.page.componentProps} />
            </Stack>
          </OnboardingLayout>
        );
      }}
      onChangeRoutes={onChangeRoutes}
      onEnd={handleEnd}
    />
  );
};
