import { useRef } from 'react';

import { useClientRepresentativeSavePowerOfAttorney } from 'api/requests';
import { lodash } from 'helpers';
import { FileUpload, useFilesUpload, useServerErrorNotify } from 'hooks';

import { OnboardingPowerOfAttorneyFormType } from '../forms/onboardingPowerOfAttorneyForm';

import { useOnboardingContext } from './useOnboardingContext';

export const usePowerOfAttorney = () => {
  const { savePowerOfAttorney, error, loading } =
    useClientRepresentativeSavePowerOfAttorney();
  const { goToBack, goToNext, data, clientId } = useOnboardingContext();

  const powerOfAttorney: OnboardingPowerOfAttorneyFormType | undefined = data
    .currentClient.powerOfAttorney
    ? {
        ...data.currentClient.powerOfAttorney,
        fileIds: data.currentClient.powerOfAttorney.files.map((v) => v.id),
      }
    : undefined;

  const initialValues = useRef(powerOfAttorney).current;

  const filesUpload: FileUpload[] | undefined =
    data.currentClient.powerOfAttorney?.files.map(
      (v) =>
        new FileUpload({ name: v.name, size: v.size }, undefined, false, v.id)
    );

  const {
    files,
    isLoadingFile: submitDisabled,
    onRemoveFile,
    onAddFiles,
  } = useFilesUpload(filesUpload);

  const onSubmit = async (values: OnboardingPowerOfAttorneyFormType) => {
    const res = await savePowerOfAttorney(clientId, {
      dateOfExpiration: values.dateOfExpiration,
      dateOfIssue: values.dateOfIssue,
      documentNumber: values.documentNumber || undefined,
      fileIds: lodash.compact(values.fileIds),
    });
    if (res) {
      goToNext();
    }
  };

  useServerErrorNotify(error);

  return {
    onSubmit,
    goToBack,
    files,
    onAddFiles,
    onRemoveFile,
    submitDisabled,
    submitLoading: loading,
    initialValues,
  };
};
