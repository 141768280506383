import { FC } from 'react';

import {
  VerificationCodeFormDialog,
  VerificationCodeFormFormErrors,
  VerificationCodeFormType,
} from 'components';
import { useTranslation } from 'libs/i18n';
import { Typography, Stack } from 'libs/ui';

import { LoginForm, LoginFormProps } from '../../components/LoginForm';

import { ContainerStyled, LoginLayoutStyled } from './styles';

export interface LoginViewProps extends LoginFormProps {
  isOpenVerificationDialog: boolean;
  onCloseVerificationDialog: () => void;
  onSubmitVerification: (values: VerificationCodeFormType) => {};
  verificationFormError?: VerificationCodeFormFormErrors;
}

export const LoginView: FC<LoginViewProps> = ({
  isOpenVerificationDialog,
  onCloseVerificationDialog,
  onSubmitVerification,
  verificationFormError,
  ...formProps
}) => {
  const { t } = useTranslation();

  return (
    <ContainerStyled direction="row">
      <LoginLayoutStyled className="login-layout" variant="outlined">
        <Stack className="login-content" justifyContent="center" spacing={48}>
          <Typography align="center" variant="title" variantMobile="subtitle">
            {t('auth.login.title')}
          </Typography>
          <LoginForm {...formProps} />
        </Stack>
      </LoginLayoutStyled>
      <div className="login-right" />

      <VerificationCodeFormDialog
        initialErrors={verificationFormError}
        isOpen={isOpenVerificationDialog}
        onClose={onCloseVerificationDialog}
        onSubmit={onSubmitVerification}
      />
    </ContainerStyled>
  );
};
