import { CountryEntity } from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import { Stack, Typography } from 'libs/ui';
import { RegistrationAddressFields } from 'modules/onboarding/common/components/OnboardingRegistrationAddress/FormContent/RegistrationAddressFields';
import {
  useFormContext,
  Field,
} from 'modules/onboarding/common/forms/onboardingRegistrationAddressForm';

export interface FormContentProps {
  countriesLoading?: boolean;
  countries: CountryEntity[];
}

export const FormContent = ({
  countriesLoading,
  countries,
}: FormContentProps) => {
  const { values } = useFormContext();
  const { t } = useTranslation();

  return (
    <Stack spacing={32}>
      <Stack spacing={24} spacingMobile={16}>
        <Typography variant="subtitle" variantMobile="bodyBold">
          {t('onboarding.registrationAddress.titleRegistration')}
        </Typography>
        <RegistrationAddressFields
          countries={countries}
          countriesLoading={countriesLoading}
          variant="Registration"
        />
      </Stack>

      <Stack spacing={24} spacingMobile={16}>
        <Stack
          direction="row-exceptMobile"
          flexWrap="wrap"
          justifyContent="space-between"
          spacing={16}
        >
          <Typography variant="subtitle" variantMobile="bodyBold">
            {t('onboarding.registrationAddress.titleResidence')}
          </Typography>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            spacing={16}
          >
            <Typography variant="bodyMedium">
              {t('onboarding.registrationAddress.residencelabel')}
            </Typography>
            <Field.Switch name="residentialAddressIsSame" />
          </Stack>
        </Stack>

        {!values.residentialAddressIsSame && (
          <RegistrationAddressFields
            countries={countries}
            countriesLoading={countriesLoading}
            variant="Residence"
          />
        )}
      </Stack>
    </Stack>
  );
};
