import { styled } from '../styled';

import { SwitchProps } from './types';

const WIDTH_SMALL = 36;
const WIDTH_MEDIUM = 50;
const HEIGHT_SMALL = 20;
const HEIGHT_MEDIUM = 27;

export const Container = styled('div')<SwitchProps>(({ theme, size }) => {
  const width = size === 'medium' ? WIDTH_MEDIUM : WIDTH_SMALL;
  const height = size === 'medium' ? HEIGHT_MEDIUM : HEIGHT_SMALL;
  return {
    backgroundColor: theme.palette.switchBg,
    borderRadius: width / 2,
    width,
    height,
    padding: 3,

    cursor: 'pointer',
    '&.disabled': {
      cursor: 'default',
      backgroundColor: theme.palette.switchDisabledBg,
    },

    '& .switch-thumbContainer': {
      width: height - 6,
      height: height - 6,
      transitionDuration: '300ms',
      transitionProperty: 'transform',
      color: theme.palette.switchThumb,

      '& .switch-thumb': {
        backgroundColor: 'currentColor',
        width: '100%',
        height: '100%',
        borderRadius: '100%',
      },
      '& .switch-customThumb': {
        width: '100%',
        height: '100%',
        alignItems: 'center',
        display: 'flex',
        marginLeft: 3,
      },
    },

    '&.checked': {
      '.switch-thumbContainer': {
        color: theme.palette.switchThumbChecked,
        transform: `translateX(${height - 4}px)`,
      },
      '& .switch-customThumb': {
        marginLeft: -3,
      },
    },

    '&.disabled .switch-thumbContainer': {
      color: theme.palette.switchDisabledThumb,
    },
  };
});
