import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';

import {
  useLegalClientCompleteLegalHeadOnboarding,
  useLegalClientGetLegalHeadLazy,
  useLegalClientLegalHeadSettingsSave,
} from 'api/requests';
import {
  LegalClientEntity,
  LegalClientRepresentativeEntity,
} from 'api/types/entity';
import { LegalClientCreateLegalHeadSaveParam } from 'api/types/params';
import { useServerErrorNotify } from 'hooks';
import { OnboardingOnChangeRoutesFunc } from 'modules/onboarding/common/providers';

import { checkFirstHeadFullness } from '../helpers/checkFirstHeadFullness';
import { pages } from '../route';
import { LegalClientLegalHeadPage } from '../types';

import { useCreateLegalHead } from './useCreateLegalHead';

export const useOnboarding = (
  currentClient: LegalClientRepresentativeEntity,
  legalClient: LegalClientEntity,
  headId?: string
) => {
  const legalHeadId = useRef(headId);

  const { saveSettings } = useLegalClientLegalHeadSettingsSave();

  const { complete } = useLegalClientCompleteLegalHeadOnboarding();

  const { getLegalHead, legalHead } = useLegalClientGetLegalHeadLazy();

  const { createLegalHead, createLegalHeadError } =
    useCreateLegalHead(legalClient);

  const [loading, setLoading] = useState(false);

  const [routes, setRoutes] = useState(legalHead?.onboarding?.routes || []);

  const initialRoutes = useMemo(
    () => (routes.length && routes) || legalHead?.onboarding?.routes || [],
    [legalHead?.onboarding?.routes, routes]
  );

  const onChangeRoutes = useCallback<OnboardingOnChangeRoutesFunc>(
    async (routes: string[], { currentPage }, type) => {
      if (routes && legalClient && headId) {
        // complete when the organ has been restored
        if (
          type === 'next' &&
          currentPage === LegalClientLegalHeadPage.headTypeInfo &&
          legalHead?.firstHeads?.every(checkFirstHeadFullness)
        ) {
          complete(legalClient.id, headId);
        }
        setRoutes(routes);
        await saveSettings(legalClient.id, headId, {
          routes,
          totalRoutes: pages.size,
        });
      }
    },
    [complete, headId, legalClient, legalHead?.firstHeads, saveSettings]
  );

  const onComplete = async () => complete(legalClient.id, legalHead!.id);

  const handleCreateLegalHead = async (
    param: LegalClientCreateLegalHeadSaveParam
  ) => {
    const legalHead = await createLegalHead(param);
    if (legalHead) {
      await getLegalHead(legalClient.id, legalHead.id);
    }
    return legalHead;
  };

  useLayoutEffect(() => {
    if (legalHeadId.current) {
      setLoading(true);
      getLegalHead(legalClient.id, legalHeadId.current).finally(() =>
        setLoading(false)
      );
    }
  }, [getLegalHead, legalClient.id]);

  useServerErrorNotify(createLegalHeadError);

  return {
    currentClient,
    legalHead,
    legalClient,
    loading,
    initialRoutes,
    onChangeRoutes,
    onComplete,
    createLegalHead: handleCreateLegalHead,
  };
};
