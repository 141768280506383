import { useCallback, useMemo } from 'react';

import { useOnboardingClient, useOnboardingSettingsSave } from 'api/requests';

import { pages } from '../route';

export const useOnboarding = (clientId: string) => {
  const { currentClient, loading, refetch } = useOnboardingClient(clientId);
  const { saveSettings } = useOnboardingSettingsSave();

  const onChangeRoutes = useCallback(
    async (routes: string[]) => {
      if (routes && currentClient) {
        await saveSettings(currentClient.id, {
          routes,
          totalRoutes: pages.size,
        });
      }
    },
    [currentClient, saveSettings]
  );

  const initialRoutes = useMemo(
    () => currentClient?.onboarding?.routes || [],
    [currentClient?.onboarding?.routes]
  );

  return {
    currentClient,
    loading: !currentClient && loading,
    onChangeRoutes,
    initialRoutes,
    refetch,
  };
};
