import {
  PageComponentProps as PageComponentPropsBase,
  RoutePage as RoutePageBase,
} from 'modules/onboarding/common/types';

export interface PageComponentProps extends PageComponentPropsBase {}

export interface RoutePage extends RoutePageBase<PageComponentProps> {}

export enum LegalClientHeadPage {
  personalData = 'personalData',
  citizenship = 'citizenship',
  headTypeInfo = 'headTypeInfo',
  taxResidency = 'taxResidency',
  registrationAddress = 'registrationAddress',
  pepConnection = 'pepConnection',
  positionInCompany = 'positionInCompany',
}
