import { LegalClientSaveLicenseInfoParam } from 'api/types/params';
import { formatToISODate } from 'helpers';
import { YesOrNoType } from 'types/yesOrNo';

import {
  CompanyLicenseFormType,
  BaseLicenseFormType,
} from '../../forms/companyLicenseForm';

export const getBaseSubmitData = (
  values: BaseLicenseFormType,
  isLicensed: boolean
) => ({
  isLicensed,
  licenseInfo: {
    activityType: values.licensedActivityType!,
    issueDate: formatToISODate(values.dateOfIssue!),
    issuingAuthority: values.authorityName!,
    licenseNumber: values.licenseNumber!,
    expirationDate: values.dateOfExpiry
      ? formatToISODate(values.dateOfExpiry)
      : undefined,
  },
});

export const getSubmitData = (
  values: CompanyLicenseFormType
): LegalClientSaveLicenseInfoParam => {
  const isLicensed =
    values.companyActivityIsLicensed?.value === YesOrNoType.Yes;

  if (!isLicensed) {
    return { isLicensed };
  }

  const baseData = getBaseSubmitData(values, isLicensed);

  return {
    ...baseData,
    licenseInfo: {
      ...baseData.licenseInfo,
      fileIds: values.fileIds!,
    },
  };
};
