import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientRepresentativeSettingsSaveMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { OnboardingSettingsSaveParam } from 'api/types/params';

export const useLegalClientRepresentativeSettingsSave = () => {
  const [handle, { data, loading, error: apolloError }] =
    useLegalClientRepresentativeSettingsSaveMutation();

  const error = useError(apolloError);

  const saveSettings = useCallback(
    async (id: string, input: OnboardingSettingsSaveParam) => {
      const res = await handle({
        variables: {
          id,
          input: {
            traversedRoutes: input.routes,
            totalRoutes: input.totalRoutes,
          },
        },
      });
      return res.data?.legalClientRepresentativeSettingsSave?.routes ?? [];
    },
    [handle]
  );

  return {
    data,
    loading,
    error,
    saveSettings,
  };
};
