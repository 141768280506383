import { ReactNode, useMemo } from 'react';

import { LoadingLayout } from 'components';

import { OnboardingContext, OnboardingContextProps } from './Context';
import {
  OnboardingNavigationProps,
  OnboardingPage,
  useOnboardingNavigation,
} from './hooks';

interface OnboardingProviderProps<T> extends OnboardingNavigationProps {
  clientId: string;
  data: T;

  renderChild: ({
    route,
    countSteps,
    currentStepIndex,
  }: {
    route: OnboardingPage;
    countSteps: number;
    currentStepIndex: number;
  }) => ReactNode;
}

export const OnboardingProvider = <T,>({
  initialRoutes = [],
  clientId,
  pages,
  data,
  renderChild,
  onEnd,
  onChangeRoutes,
}: OnboardingProviderProps<T>) => {
  const { route, goToBack, goToNext, currentPageIndex } =
    useOnboardingNavigation({
      pages,
      initialRoutes,
      onChangeRoutes,
      onEnd,
    });

  const value: OnboardingContextProps<T> = useMemo(
    () => ({
      data,
      clientId,
      goToNext,
      goToBack,
      step: { count: pages.size, current: currentPageIndex + 1 },
    }),
    [clientId, data, goToBack, goToNext, pages.size, currentPageIndex]
  );

  return (
    <OnboardingContext.Provider value={value}>
      {route !== undefined ? (
        renderChild({
          route,
          countSteps: pages.size,
          currentStepIndex: currentPageIndex,
        })
      ) : (
        <LoadingLayout />
      )}
    </OnboardingContext.Provider>
  );
};
