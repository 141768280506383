import { LoadingLayout } from 'components';
import { config } from 'config';
import { useInterval } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { usePageTitle } from 'libs/navigation';
import {
  OnboardingLayout,
  OnboardingStatus,
} from 'modules/onboarding/common/components';
import { STATUS_UPDATE_INTERVAL } from 'modules/onboarding/common/constants';
import { OnboardingProvider } from 'modules/onboarding/common/providers';

import { WaitingParticipantsKyc } from '../components/WaitingParticipantsKyc';
import { useLegalClientOnboardingStatus } from '../hooks';
import { useOnboarding } from '../hooks/useOnboarding';
import { HeadPageType } from '../hooks/useOnboarding/type';
import { useParticipantsWaitingKyc } from '../hooks/useParticipantsWaitingKyc';
import { LegalClientBeneficiary } from '../innerOnboarding/LegalClientBeneficiary';
import { LegalClientBranch } from '../innerOnboarding/legalClientBranch';
import { LegalClientHead } from '../innerOnboarding/legalClientHead';
import { LegalClientLegalHead } from '../innerOnboarding/legalClientLegalHead';
import { LegalClientLegalHeadFirstHead } from '../innerOnboarding/legalClientLegalHeadFirstHead';

interface Props {
  clientId: string;
}

export const LegalContainer = ({ clientId }: Props) => {
  const { t } = useTranslation();

  usePageTitle(t('onboarding.pageTitle'));

  const {
    currentClient,
    legalClient,
    loading,
    pages,
    initialRoutes,
    innerState,
    refetchCurrentClient,
    refetchLegalClient,
    onEnd,
    onChangeRoutes,
  } = useOnboarding(clientId);

  const {
    isWaitingKyc,
    users,
    formErrorSaveEmail,
    loadingSaveEmail,
    onEditEmail,
    onResendCode,
    getLegalClientParticipants,
  } = useParticipantsWaitingKyc(currentClient, legalClient);

  const onboardingStatus = useLegalClientOnboardingStatus(
    legalClient,
    currentClient
  );

  useInterval(
    () => {
      refetchCurrentClient();
      refetchLegalClient();
      if (legalClient && isWaitingKyc) {
        getLegalClientParticipants(legalClient.id);
      }
    },
    onboardingStatus || isWaitingKyc ? STATUS_UPDATE_INTERVAL : null
  );

  if (!currentClient || loading) {
    return <LoadingLayout />;
  }

  if (onboardingStatus) {
    return (
      <OnboardingStatus
        email={config.EMAIL ?? ''}
        onboardingStatus={onboardingStatus}
      />
    );
  }

  if (isWaitingKyc) {
    return (
      <WaitingParticipantsKyc
        email={config.EMAIL ?? ''}
        initialEmailEditErrors={formErrorSaveEmail}
        loadingEmailEdit={loadingSaveEmail}
        users={users}
        onEditEmail={onEditEmail}
        onResend={onResendCode}
      />
    );
  }

  // Head
  if (innerState.isHead && legalClient) {
    switch (innerState.state?.type) {
      case HeadPageType.person:
        return (
          <LegalClientHead
            currentClient={currentClient}
            goBack={innerState.removeState}
            id={innerState.state?.headId}
            legalClient={legalClient}
            onCreateHead={(id) => innerState.setHead(HeadPageType.person, id)}
          />
        );

      case HeadPageType.legal:
        return (
          <LegalClientLegalHead
            currentClient={currentClient}
            goBack={innerState.removeState}
            id={innerState.state?.headId!}
            legalClient={legalClient}
            onCreateLegalHead={(id) =>
              innerState.setHead(HeadPageType.legal, id)
            }
            onSetFirstHead={(id) =>
              innerState.setFirstHead(innerState.state?.headId!, id)
            }
          />
        );

      case HeadPageType.legalFirstHead:
        return (
          <LegalClientLegalHeadFirstHead
            currentClient={currentClient}
            goBack={() =>
              innerState.setHead(HeadPageType.legal, innerState.state?.headId!)
            }
            id={innerState.state?.legalFirstHeadId}
            legalClient={legalClient}
            legalHeadId={innerState.state?.headId!}
            onCreateFirstHead={(id) =>
              innerState.setFirstHead(innerState.state?.headId!, id)
            }
          />
        );

      default:
        break;
    }
  }

  // Beneficiary
  if (
    innerState.isBeneficiary &&
    legalClient &&
    innerState.state?.type === HeadPageType.beneficiary
  ) {
    return (
      <LegalClientBeneficiary
        currentClient={currentClient}
        goBack={innerState.removeState}
        id={innerState.state?.beneficiaryId!}
        legalClient={legalClient}
        onCreateBeneficiary={(id) => innerState.setBeneficiary(id)}
      />
    );
  }

  // Branch
  if (
    innerState.isBranch &&
    legalClient &&
    innerState.state?.type === HeadPageType.branch
  ) {
    return (
      <LegalClientBranch
        currentClient={currentClient}
        goBack={innerState.removeState}
        id={innerState.state?.branchId!}
        legalClient={legalClient}
        onCreateBranch={(id) => innerState.setBranch(id)}
      />
    );
  }

  return (
    <OnboardingProvider
      clientId={currentClient.id}
      data={{ currentClient, legalClient, innerState }}
      initialRoutes={initialRoutes}
      pages={pages}
      renderChild={({ route, countSteps, currentStepIndex }) => {
        const Component = route.page.component;
        return (
          <OnboardingLayout
            key={route.pageKey}
            countSteps={countSteps}
            currentStepIndex={currentStepIndex}
          >
            <Component {...route.page.componentProps} />
          </OnboardingLayout>
        );
      }}
      onChangeRoutes={onChangeRoutes}
      onEnd={onEnd}
    />
  );
};
