import { yesOrNotSelectValidation } from 'helpers';
import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  string,
  object,
  date,
  array,
} from 'libs/form';
import { TFunc } from 'libs/i18n';
import {
  getToday,
  getTodayWithResetHours,
} from 'modules/onboarding/common/helpers/getToday';
import { Maybe } from 'types/maybe';
import { YesOrNoSelectType, YesOrNoType } from 'types/yesOrNo';

export interface BaseLicenseFormType {
  companyActivityIsLicensed: Maybe<YesOrNoSelectType>;
  licensedActivityType?: string;
  licenseNumber?: string;
  dateOfIssue?: Date;
  dateOfExpiry?: Date | null;
  authorityName?: string;
}
export interface CompanyLicenseFormType extends BaseLicenseFormType {
  fileIds?: string[];
}

export const MAX_FILES = 10;
export const maxLengthLicenseNumber = 200;
export const maxLengthActivityType = 2000;
export const maxLengthAuthorityName = 2000;

const companyActivityIsLicensed = (v: YesOrNoSelectType) =>
  v?.value === YesOrNoType.Yes;

export const getBaseSchema = (t: TFunc): ObjectSchema<BaseLicenseFormType> =>
  object({
    companyActivityIsLicensed: yesOrNotSelectValidation
      .default(null)
      .required(),
    licensedActivityType: string()
      .max(maxLengthActivityType)
      .when('companyActivityIsLicensed', {
        is: companyActivityIsLicensed,
        then: (schema) => schema.required(),
      }),
    licenseNumber: string()
      .max(maxLengthLicenseNumber)
      .when('companyActivityIsLicensed', {
        is: companyActivityIsLicensed,
        then: (schema) => schema.required(),
      }),
    dateOfIssue: date()
      .when('companyActivityIsLicensed', {
        is: companyActivityIsLicensed,
        then: (schema) => schema.required(),
      })
      .max(getToday(), t('validation.maxDate')),
    dateOfExpiry: date()
      .min(getTodayWithResetHours(), t('validation.minDate'))
      .nullable()
      .default(null),
    authorityName: string()
      .max(2000)
      .when('companyActivityIsLicensed', {
        is: companyActivityIsLicensed,
        then: (schema) => schema.required(),
      }),
  });

export const getSchema = (t: TFunc): ObjectSchema<CompanyLicenseFormType> =>
  getBaseSchema(t).concat(
    object({
      fileIds: array()
        .max(MAX_FILES)
        .when('companyActivityIsLicensed', {
          is: companyActivityIsLicensed,
          then: (schema) => schema.of(string().required()).required().min(1),
        }),
    })
  );

export const { Field, Form, useFormContext, Submit } =
  TypedForm<CompanyLicenseFormType>();

export type FormErrors = FormErrorsBase<CompanyLicenseFormType>;
