import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  useClientRepresentativeSettingsSave,
  useOnboardingClientLazy,
  useOnboardingClientRepresentative,
} from 'api/requests';

import { pages } from '../route';

export const useOnboarding = (clientId: string) => {
  const {
    currentClient,
    loading,
    refetch: refetchCurrentClient,
  } = useOnboardingClientRepresentative(clientId);

  const { saveSettings } = useClientRepresentativeSettingsSave();

  const [mainLoading, setMainLoading] = useState(true);

  const {
    client: ownerClient,
    getClient,
    refetch: refetchOwnerClient,
  } = useOnboardingClientLazy();

  const onChangeRoutes = useCallback(
    async (routes: string[]) => {
      if (routes && currentClient) {
        await saveSettings(currentClient.id, {
          routes,
          totalRoutes: pages.size,
        });
      }
    },
    [currentClient, saveSettings]
  );

  const initialRoutes = useMemo(
    () => currentClient?.onboarding?.routes || [],
    [currentClient?.onboarding?.routes]
  );

  // get owner client
  useEffect(() => {
    if (currentClient?.id) {
      if (currentClient?.clientId) {
        getClient(currentClient.clientId).finally(() => {
          setMainLoading(false);
        });
      } else {
        setMainLoading(false);
      }
    }
  }, [currentClient?.clientId, currentClient?.id, getClient]);

  return {
    currentClient,
    ownerClient,
    loading: (!currentClient && loading) || mainLoading,
    initialRoutes,
    refetchOwnerClient,
    onChangeRoutes,
    refetchCurrentClient,
  };
};
