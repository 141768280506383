import { AddressesParam } from 'api/types/params/onboarding/addressesParam';

import {
  BaseOnboardingRegistrationAddressFormType,
  OnboardingRegistrationAddressFormType,
} from '../../forms/onboardingRegistrationAddressForm';

export const getBaseSubmitData = (
  values: BaseOnboardingRegistrationAddressFormType
) => {
  const registrationAddress = {
    country: values.countryRegistration?.id!,
    region: values.regionRegistration,
    apartment: values.apartmentRegistration,
    building: values.housingRegistration,
    district: values.areaRegistration,
    house: values.houseNumberRegistration,
    locality: values.localityRegistration,
    postalCode: values.postcodeRegistration,
    street: values.streetRegistration,
  };
  const result = {
    registrationAddress,

    residentialAddress: !values.residentialAddressIsSame
      ? {
          country: values.countryResidence?.id!,
          region: values.regionResidence!,
          apartment: values.apartmentResidence!,
          building: values.housingResidence,
          district: values.areaResidence,
          house: values.houseNumberResidence!,
          locality: values.localityResidence!,
          postalCode: values.postcodeResidence!,
          street: values.streetResidence!,
        }
      : registrationAddress,
  };

  return result;
};

export const getSubmitData = (
  values: OnboardingRegistrationAddressFormType
) => {
  const baseSubmitData = getBaseSubmitData(values);

  const result: AddressesParam = {
    ...baseSubmitData,
    placeOfBirth: {
      country: values.countryOfBirth?.id!,
      locality: values.localityOfBirth ?? '',
    },
  };

  return result;
};
