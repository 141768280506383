import {
  LegalClientBaseLicenseInfoEntity,
  LegalClientLicenseInfoEntity,
} from 'api/types/entity';
import { yesOrNot } from 'helpers';
import { TFunc } from 'libs/i18n';

import {
  BaseLicenseFormType,
  CompanyLicenseFormType,
} from '../../forms/companyLicenseForm';

interface Props {
  isLicensed?: boolean;
  licenseInfo?: LegalClientLicenseInfoEntity;
}

export const getBaseInitialData = (
  data: GetBaseInitialDataProps,
  t: TFunc
): BaseLicenseFormType => ({
  companyActivityIsLicensed: yesOrNot(t, data.isLicensed),
  authorityName: data.licenseInfo?.issuingAuthority,
  dateOfExpiry: data.licenseInfo?.expirationDate,
  dateOfIssue: data.licenseInfo?.issueDate,
  licensedActivityType: data.licenseInfo?.activityType,
  licenseNumber: data.licenseInfo?.licenseNumber,
});

export const getInitialData = (
  client: Props,
  t: TFunc
): CompanyLicenseFormType => ({
  ...getBaseInitialData(client, t),
  fileIds: client.licenseInfo?.files?.map((file) => file.id),
});

type GetBaseInitialDataProps = {
  isLicensed?: boolean;
  licenseInfo?: LegalClientBaseLicenseInfoEntity;
};
