import { useMemo } from 'react';

import { CountryEntity, CurrencyType } from 'api/types/entity';
import { FileUpload } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { FileType } from 'libs/ui';
import {
  Field,
  MAX_FILES,
} from 'modules/onboarding/common/forms/withdrawalAccountsForm';

interface Props {
  index: number;
  countriesLoading?: boolean;
  countries: CountryEntity[];
  files?: FileUpload[];
  onAddFiles?: (file: File[], index: number) => void;
  onRemoveFile?: (file: FileUpload) => void;
}

export const Fields = ({
  index,
  countriesLoading,
  countries,
  files,
  onAddFiles,
  onRemoveFile,
}: Props) => {
  const { t } = useTranslation();
  const name = `withdrawalAccounts.${index}`;

  const filteredFiles = useMemo(
    () => files?.filter((v) => v.data === index),
    [files, index]
  );

  return (
    <>
      <Field.Autocomplete
        label={t('onboarding.withdrawalAccounts.bankCountry.label')}
        loading={countriesLoading}
        name={`${name}.bankCountry` as 'withdrawalAccounts'}
        optionLabelKey="name"
        options={countries}
        optionValueKey="id"
        placeholder={t('onboarding.withdrawalAccounts.bankCountry.placeholder')}
      />

      <Field.Autocomplete
        label={t('onboarding.withdrawalAccounts.currency.label')}
        loading={countriesLoading}
        name={`${name}.currency` as 'withdrawalAccounts'}
        optionLabelKey="label"
        options={Object.values(CurrencyType).map((v) => ({
          value: v,
          label: v,
        }))}
        optionValueKey="value"
        placeholder={t('onboarding.withdrawalAccounts.currency.placeholder')}
        variant="select"
      />

      <Field.Text
        label={t('onboarding.withdrawalAccounts.swiftCode.label')}
        name={`${name}.swiftCode` as 'withdrawalAccounts'}
        placeholder={t('onboarding.withdrawalAccounts.swiftCode.placeholder')}
      />

      <Field.Text
        label={t('onboarding.withdrawalAccounts.bankName.label')}
        name={`${name}.bankName` as 'withdrawalAccounts'}
        placeholder={t('onboarding.withdrawalAccounts.bankName.placeholder')}
      />

      <Field.Text
        label={t('onboarding.withdrawalAccounts.bankAddress.label')}
        name={`${name}.bankAddress` as 'withdrawalAccounts'}
        placeholder={t('onboarding.withdrawalAccounts.bankAddress.placeholder')}
      />

      <Field.Text
        label={t('onboarding.withdrawalAccounts.checkingAccount.label')}
        name={`${name}.checkingAccount` as 'withdrawalAccounts'}
        placeholder={t(
          'onboarding.withdrawalAccounts.checkingAccount.placeholder'
        )}
      />

      <Field.Text
        label={t('onboarding.withdrawalAccounts.correspondentAccount.label')}
        name={`${name}.correspondentAccount` as 'withdrawalAccounts'}
        placeholder={t(
          'onboarding.withdrawalAccounts.correspondentAccount.placeholder'
        )}
      />

      <Field.FilesUpload
        files={filteredFiles}
        fileTypes={[FileType.pdf]}
        maxFiles={MAX_FILES}
        name={`${name}.ownerConfirmationFileIds` as 'withdrawalAccounts'}
        title={t('onboarding.withdrawalAccounts.documentTitle')}
        onAddFiles={(f) => onAddFiles?.(f, index)}
        onRemoveFile={onRemoveFile}
      />
    </>
  );
};
