import {
  LegalClientEntity,
  LegalClientRepresentativeEntity,
} from 'api/types/entity';
import { LegalClientCreateLegalHeadSaveParam } from 'api/types/params';
import { LoadingLayout } from 'components';
import { useTranslation } from 'libs/i18n';
import { Stack } from 'libs/ui';
import {
  BackBody,
  OnboardingLayout,
} from 'modules/onboarding/common/components';
import { OnboardingProvider } from 'modules/onboarding/common/providers';

import { useOnboarding } from '../hooks/useOnboarding';
import { pages } from '../route';

interface Props {
  currentClient: LegalClientRepresentativeEntity;
  legalClient: LegalClientEntity;
  id?: string;
  goBack: () => void;
  onSetFirstHead: (id?: string) => void;
  onCreateLegalHead: (id: string) => void;
}

export const LegalClientLegalHeadContainer = ({
  currentClient,
  legalClient,
  id,
  goBack,
  onSetFirstHead,
  onCreateLegalHead,
}: Props) => {
  const {
    loading,
    initialRoutes,
    legalHead,
    onChangeRoutes,
    createLegalHead,
    onComplete,
  } = useOnboarding(currentClient, legalClient, id);

  const { t } = useTranslation();

  if (loading) {
    return <LoadingLayout />;
  }

  const handleEnd = async () => {
    if (await onComplete()) {
      goBack();
    }
  };

  const handleCreateLegalHead = async (
    param: LegalClientCreateLegalHeadSaveParam
  ) => {
    const legalHead = await createLegalHead(param);
    if (legalHead) {
      onCreateLegalHead(legalHead.id);
    }
    return !!legalHead;
  };

  return (
    <OnboardingProvider
      clientId={currentClient.id}
      data={{
        currentClient,
        legalClient,
        legalHead,
        onSetFirstHead,
        onCreateLegalHead: handleCreateLegalHead,
      }}
      initialRoutes={initialRoutes}
      pages={pages}
      renderChild={({ route }) => {
        const Component = route.page.component;
        return (
          <OnboardingLayout key={route.pageKey} headerComponent={<div />}>
            <Stack spacing={16}>
              <BackBody
                text={t('onboarding.legalClientHead.backTitle')}
                onClick={goBack}
              />
              <Component {...route.page.componentProps} />
            </Stack>
          </OnboardingLayout>
        );
      }}
      onChangeRoutes={onChangeRoutes}
      onEnd={handleEnd}
    />
  );
};
