import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientGetBranchLazyQuery } from 'api/generated/graphql';
import { useError } from 'api/helpers';

import { parseLegalClientBranchEntity } from './helpers';

export const useLegalClientGetBranchLazy = () => {
  const [query, { data, loading, error: apolloError }] =
    useLegalClientGetBranchLazyQuery();

  const error = useError(apolloError);

  const getBranch = useCallback(
    async (legalId: string, branchId: string) => {
      const res = await query({ variables: { id: legalId, branchId } });
      if (res.data?.legalClientGetBranch) {
        return parseLegalClientBranchEntity(res.data?.legalClientGetBranch);
      }
      return null;
    },
    [query]
  );

  return {
    legalClientBranch: data?.legalClientGetBranch
      ? parseLegalClientBranchEntity(data.legalClientGetBranch)
      : undefined,
    loading,
    getBranch,
    error,
  };
};
