import { useRef } from 'react';

import { useLegalClientBeneficiarySaveAddresses } from 'api/requests/onboarding/legal/beneficiary';
import { useServerErrorNotify } from 'hooks';
import { OnboardingRegistrationAddressFormType } from 'modules/onboarding/common/forms/onboardingRegistrationAddressForm';
import {
  getInitialData,
  getSubmitData,
} from 'modules/onboarding/common/helpers/registrationAddress';
import { useOnboardingCountries } from 'modules/onboarding/common/hooks';

import { useOnboardingContext } from './useOnboardingContext';

export const useRegistrationAddress = () => {
  const { goToBack, goToNext, data } = useOnboardingContext();

  const { countries, loading: countriesLoading } = useOnboardingCountries();

  const {
    saveAddresses,
    loading: submitLoading,
    error,
  } = useLegalClientBeneficiarySaveAddresses();

  const initialValues = useRef(
    getInitialData(data.legalClientBeneficiary)
  ).current;

  const onSubmit = async (values: OnboardingRegistrationAddressFormType) => {
    if (values) {
      const submitData = getSubmitData(values);
      await saveAddresses(
        data.legalClient.id,
        data.legalClientBeneficiary.id,
        submitData
      );
      goToNext();
    }
  };

  useServerErrorNotify(error);

  return {
    onSubmit,
    initialValues,
    countries,
    countriesLoading,
    submitLoading,
    goToBack,
  };
};
