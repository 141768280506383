import { useColor } from 'hooks';
import { ColorsDark, ColorsLight } from 'libs/ui/theme';

import { SvgIcon } from '../SvgIcon';
import { SvgProps } from '../types';

export const Close = ({ size = 24, color, className }: SvgProps) => {
  const defaultColor = useColor({
    dark: ColorsDark.white,
    light: ColorsLight.black,
  });
  color = color || defaultColor;
  return (
    <SvgIcon className={className} size={size}>
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18 6L6 18M18 18L6 6.00001"
          stroke={color}
          strokeLinecap="round"
          strokeWidth="2"
        />
      </svg>
    </SvgIcon>
  );
};
