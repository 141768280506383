import { getYesOrNoOptions } from 'helpers';
import { useTranslation } from 'libs/i18n';
import { Fields } from 'modules/onboarding/legal/components/CompanyLicense/FormContent';
import { YesOrNoType } from 'types/yesOrNo';

import { useFormContext, Field } from '../../../forms/licenseForm';

export interface FormContentProps {}

export const FormContent = () => {
  const { t } = useTranslation();
  const { values } = useFormContext();
  return (
    <>
      <Field.Autocomplete
        label={t(
          'onboarding.legalClientBranch.license.activityIsLicensed.label'
        )}
        name="companyActivityIsLicensed"
        optionLabelKey="label"
        options={getYesOrNoOptions(t)}
        optionValueKey="value"
        placeholder={t('common.select.placeholder')}
        variant="select"
      />
      {values.companyActivityIsLicensed &&
        values.companyActivityIsLicensed.value === YesOrNoType.Yes && (
          <Fields />
        )}
    </>
  );
};
