import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientBranchSaveLicenseInfoMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { LegalClientBranchSaveLicenseInfoParam } from 'api/types/params/onboarding/branch/legalClientBranchSaveLicenseInfoParam';

import { parseLegalClientBranchEntity } from './helpers';

export const useLegalClientBranchSaveLicenseInfo = () => {
  const [handle, { data, loading, error: apolloError }] =
    useLegalClientBranchSaveLicenseInfoMutation();

  const error = useError(apolloError);

  return {
    data: data?.legalClientBranchSaveLicenseInfo
      ? parseLegalClientBranchEntity(data.legalClientBranchSaveLicenseInfo)
      : undefined,
    loading,
    error,
    saveAddresses: useCallback(
      async (
        id: string,
        branchId: string,
        params: LegalClientBranchSaveLicenseInfoParam
      ) => {
        const res = await handle({
          variables: {
            id,
            branchId,
            input: params,
          },
        });
        return res?.data?.legalClientBranchSaveLicenseInfo
          ? parseLegalClientBranchEntity(
              res.data.legalClientBranchSaveLicenseInfo
            )
          : undefined;
      },
      [handle]
    ),
  };
};
