import { useCallback } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useLegalClientLegalHeadFirstHeadSaveAddressesMutation } from 'api/generated/graphql';
import { useError } from 'api/helpers';
import { LegalClientHeadSaveAddresses } from 'api/types/params';

import { parseLegalClientLegalHeadFirstHeadEntity } from './helpers';

export const useLegalClientLegalHeadFirstHeadSaveAddresses = () => {
  const [handle, { data, loading, error: apolloError }] =
    useLegalClientLegalHeadFirstHeadSaveAddressesMutation();

  const error = useError(apolloError);

  return {
    data: data?.legalClientLegalHeadFirstHeadSaveAddresses
      ? parseLegalClientLegalHeadFirstHeadEntity(
          data.legalClientLegalHeadFirstHeadSaveAddresses
        )
      : undefined,
    loading,
    error,
    saveAddresses: useCallback(
      async (
        id: string,
        legalHeadId: string,
        firstHeadId: string,
        params: LegalClientHeadSaveAddresses
      ) => {
        const res = await handle({
          variables: {
            id,
            legalHeadId,
            firstHeadId,
            input: params,
          },
        });
        return res?.data?.legalClientLegalHeadFirstHeadSaveAddresses
          ? parseLegalClientLegalHeadFirstHeadEntity(
              res.data.legalClientLegalHeadFirstHeadSaveAddresses
            )
          : undefined;
      },
      [handle]
    ),
  };
};
