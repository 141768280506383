import { createContext } from 'react';

export interface OnboardingContextProps<T> {
  clientId: string;
  data: T;
  step: {
    count: number;
    current: number;
  };
  goToNext: () => void;
  goToBack: () => void;
}

export const OnboardingContext = createContext<OnboardingContextProps<unknown>>(
  null as never
);
