import { Body } from 'components';
import { CSSObject, styled } from 'libs/ui/styled';

export const HeaderStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,

  [theme.breakpoints.down('mobile')]: {
    gap: 12,
  } as CSSObject,

  '.formContainer-title': {
    whiteSpace: 'pre-line',

    [theme.breakpoints.down('mobile')]: {
      lineHeight: '112%',
    } as CSSObject,
  },
}));

export const ContentStyled = styled(Body)(() => ({}));

export const FooterStyled = styled('div')({
  display: 'flex',
  justifyContent: 'end',
  marginTop: 32,
  alignItems: 'center',
  gap: 40,
});

export const FieldsStyled = styled('div')({
  marginTop: 32,
  display: 'flex',
  flexDirection: 'column',
  gap: 24,
});
