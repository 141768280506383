// eslint-disable-next-line no-restricted-imports
import { PaletteOptions } from '@mui/material';

import { ColorsLight, ColorsDark } from './colors';

/**
 * Defines the palette options for the light theme.
 */
const light: PaletteOptions = {
  error: { main: ColorsLight.red },
  success: { main: ColorsLight.blue200 },
  text: { primary: ColorsLight.black, gray: ColorsLight.gray250 },
  background: { default: ColorsLight.gray50 },

  body: ColorsLight.white,
  shadow: { main: '0px 24px 38px 0px rgba(45, 54, 72, 0.02)' },

  /** SWITCH */
  switchBg: ColorsLight.gray100,
  switchThumb: ColorsLight.white,
  switchThumbChecked: ColorsLight.black,
  switchDisabledBg: ColorsLight.gray200,
  switchDisabledThumb: ColorsLight.gray250,

  /** SELECT */
  selectBg: ColorsLight.white,
  selectMenuItem: ColorsLight.black,
  selectSelectedMenuItem: ColorsLight.blue150,
  selectMenuItemBorder: ColorsLight.gray50,

  /** CHECKBOX */
  checkboxBorder: ColorsLight.gray200,
  checkboxHover: ColorsLight.gray50,
  checkboxDisabledBg: ColorsLight.gray100,
  checkboxSelectedBorder: ColorsLight.black,
  checkboxSelectedBg: ColorsLight.black,
  checkboxSelectedIconBg: ColorsLight.white,
  checkboxSelectedHoverIconBg: ColorsLight.gray100,
  checkboxSelectedDisabledIconBg: ColorsLight.gray50,

  /** INPUT */
  inputLabel: ColorsLight.black,
  inputPlaceholder: ColorsLight.gray250,
  inputBorder: ColorsLight.gray200,
  inputBorderError: ColorsLight.red,
  inputBorderFocused: ColorsLight.blue150,
  inputBorderHover: ColorsLight.black,
};

/**
 * Defines the palette options for the dark theme.
 */
const dark: PaletteOptions = {
  error: { main: ColorsDark.red },
  success: { main: ColorsDark.blue200 },
  text: { primary: ColorsDark.white, gray: ColorsDark.gray250 },
  background: { default: ColorsDark.gray50 },

  body: ColorsDark.black,
  shadow: { main: '0px 4px 4.7px 0px rgba(12, 18, 28, 0.09)' },

  /** SWITCH */
  switchBg: ColorsDark.gray100,
  switchThumb: ColorsDark.black,
  switchThumbChecked: ColorsDark.white,
  switchDisabledBg: ColorsDark.gray200,
  switchDisabledThumb: ColorsDark.gray250,

  /** CHECKBOX */
  checkboxBorder: ColorsDark.gray200,
  checkboxHover: ColorsDark.gray50,
  checkboxDisabledBg: ColorsDark.gray100,
  checkboxSelectedBorder: ColorsDark.white,
  checkboxSelectedBg: ColorsDark.white,
  checkboxSelectedIconBg: ColorsDark.black,
  checkboxSelectedHoverIconBg: ColorsDark.gray100,
  checkboxSelectedDisabledIconBg: ColorsDark.gray50,

  /** SELECT */
  selectBg: ColorsDark.black,
  selectMenuItem: ColorsDark.white,
  selectSelectedMenuItem: ColorsDark.blue150,
  selectMenuItemBorder: ColorsDark.gray50,

  /** INPUT */
  inputLabel: ColorsDark.white,
  inputPlaceholder: ColorsDark.gray250,
  inputBorder: ColorsDark.gray200,
  inputBorderError: ColorsDark.red,
  inputBorderFocused: ColorsDark.blue150,
  inputBorderHover: ColorsDark.white,
};

/**
 * Object that contains palette options for both light and dark themes.
 */
export const palettes = {
  light,
  dark,
};

/**
 * Alias for the palette type of the light theme.
 */
export type PaletteType = typeof light;
