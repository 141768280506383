import { CountryEntity } from 'api/types/entity';
import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
  string,
  object,
  boolean,
} from 'libs/form';
import { Maybe } from 'types/maybe';

export interface BaseOnboardingRegistrationAddressFormType {
  countryRegistration: Maybe<CountryEntity>;
  localityRegistration: string;
  regionRegistration: string;
  areaRegistration?: string;
  streetRegistration: string;
  houseNumberRegistration: string;
  housingRegistration?: string;
  apartmentRegistration?: string;
  postcodeRegistration: string;

  residentialAddressIsSame: boolean;

  countryResidence?: Maybe<CountryEntity>;
  localityResidence?: string;
  regionResidence?: string;
  areaResidence?: string;
  streetResidence?: string;
  houseNumberResidence?: string;
  housingResidence?: string;
  apartmentResidence?: string;
  postcodeResidence?: string;
}

export const getBaseSchema =
  (): ObjectSchema<BaseOnboardingRegistrationAddressFormType> =>
    object({
      countryRegistration: object({
        id: string().required(),
        name: string().required(),
      })
        .required()
        .default(null),
      localityRegistration: string().required().max(200),
      regionRegistration: string().required().max(200),
      areaRegistration: string().max(200),
      streetRegistration: string().required().max(200),
      houseNumberRegistration: string().required().max(200),
      housingRegistration: string().max(200),
      apartmentRegistration: string().max(200),
      postcodeRegistration: string().required().max(200),

      residentialAddressIsSame: boolean().default(false),

      countryResidence: object({
        id: string().required(),
        name: string().required(),
      })
        .when('residentialAddressIsSame', {
          is: false,
          then: (schema) => schema.required(),
        })
        .default(null)
        .nullable(),

      localityResidence: string().when('residentialAddressIsSame', {
        is: false,
        then: (schema) => schema.required().max(200),
      }),
      regionResidence: string().when('residentialAddressIsSame', {
        is: false,
        then: (schema) => schema.required().max(200),
      }),
      areaResidence: string().when('residentialAddressIsSame', {
        is: false,
        then: (schema) => schema.max(200),
      }),
      streetResidence: string().when('residentialAddressIsSame', {
        is: false,
        then: (schema) => schema.required().max(200),
      }),
      houseNumberResidence: string().when('residentialAddressIsSame', {
        is: false,
        then: (schema) => schema.required().max(200),
      }),
      housingResidence: string().when('residentialAddressIsSame', {
        is: false,
        then: (schema) => schema.max(200),
      }),
      apartmentResidence: string().when('residentialAddressIsSame', {
        is: false,
        then: (schema) => schema.max(200),
      }),
      postcodeResidence: string().when('residentialAddressIsSame', {
        is: false,
        then: (schema) => schema.required().max(200),
      }),
    });

export interface OnboardingRegistrationAddressFormType
  extends BaseOnboardingRegistrationAddressFormType {
  countryOfBirth: Maybe<CountryEntity>;
  localityOfBirth: string;
}

export const getSchema =
  (): ObjectSchema<OnboardingRegistrationAddressFormType> =>
    object({
      countryOfBirth: object({
        id: string().required(),
        name: string().required(),
      })
        .required()
        .default(null),
      localityOfBirth: string().required().max(200),
    }).concat(getBaseSchema());

export const { Field, Form, useFormContext, Submit } =
  TypedForm<OnboardingRegistrationAddressFormType>();

export type FormErrors = FormErrorsBase<OnboardingRegistrationAddressFormType>;
