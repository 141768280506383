import { FC, PropsWithChildren } from 'react';

import { cx } from 'libs/ui/theme';

import { Header } from './Header';
import { LayoutStyled, LayoutStyledProps } from './Layout.styles';
import { WrapperStyled } from './Wrapper.styles';

export interface LayoutProps extends LayoutStyledProps {
  className?: string;
}

export const Layout: FC<LayoutProps & PropsWithChildren> = ({
  children,
  variant,
  className,
}) => (
  <LayoutStyled className={cx('layout', className)} variant={variant}>
    <Header className="layout-header" />
    <WrapperStyled className="layout-content">{children}</WrapperStyled>
    <div className="layout-footer" />
  </LayoutStyled>
);
