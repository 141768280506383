// eslint-disable-next-line no-restricted-imports
import { SaveFinancingSourcesDto_Input } from 'api/generated/graphql';
import { ClientSaveFinancingSourcesParam } from 'api/types/params';

import {
  broker,
  incomeSources,
  plannedOperationTypes,
} from './parseFinancingSourcesParams';
import { parsePrice } from './parsePrice';

export const parseSaveFinancingSourcesParam = (
  data: ClientSaveFinancingSourcesParam
): SaveFinancingSourcesDto_Input => ({
  otherBrokerRelationshipPurpose: data.otherBrokerRelationshipPurpose || null,
  otherIncomeSource: data.otherIncomeSource || null,
  otherPlannedOperationType: data.otherPlannedOperationType || null,
  incomeAmount: parsePrice(data.incomeAmount),
  plannedAssetAmount: parsePrice(data.plannedAssetAmount),
  incomeSourceFileIds: data.incomeSourceFileIds,
  incomeSources: data.incomeSources.map((v) => incomeSources[v]),
  brokerRelationshipPurposes: data.brokerRelationshipPurpose.map(
    (v) => broker[v]
  ),
  plannedOperationTypes: data.plannedOperationTypes.map(
    (v) => plannedOperationTypes[v]
  ),
});
