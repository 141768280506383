// eslint-disable-next-line no-restricted-imports
import {
  MutationInput_ClientController_Update_Input_FinancingSources_BrokerRelationshipPurposes_Items as BrokerRelationshipPurposeApi,
  MutationInput_ClientController_Update_Input_FinancingSources_IncomeSources_Items as IncomeSourcesApi,
  MutationInput_ClientController_Update_Input_FinancingSources_PlannedOperationTypes_Items as PlannedOperationTypesApi,
  UpdateClientDto_Input,
} from 'api/generated/graphql';
import {
  BrokerRelationshipPurposeType,
  IncomeSourcesType,
  PlannedOperationType,
} from 'api/types/entity';
import { FinancingSourcesParam } from 'api/types/params';

import { parsePrice } from './parsePrice';

export const broker: Record<
  BrokerRelationshipPurposeType,
  BrokerRelationshipPurposeApi
> = {
  [BrokerRelationshipPurposeType.Other]: BrokerRelationshipPurposeApi.Other,
  [BrokerRelationshipPurposeType.SecuritiesBrokerage]:
    BrokerRelationshipPurposeApi.SecuritiesBrokerage,
  [BrokerRelationshipPurposeType.TrustAssetManagement]:
    BrokerRelationshipPurposeApi.TrustAssetManagement,
};

export const plannedOperationTypes: Record<
  PlannedOperationType,
  PlannedOperationTypesApi
> = {
  [PlannedOperationType.ForeignCurrencyTransactions]:
    PlannedOperationTypesApi.ForeignCurrencyTransactions,
  [PlannedOperationType.ForeignIssuersBondsTransactions]:
    PlannedOperationTypesApi.ForeignIssuersBondsTransactions,
  [PlannedOperationType.ForeignIssuersStockTransactions]:
    PlannedOperationTypesApi.ForeignIssuersStockTransactions,
  [PlannedOperationType.KazakhIssuersBondsTransactions]:
    PlannedOperationTypesApi.KazakhIssuersBondsTransactions,
  [PlannedOperationType.KazakhIssuersStockTransactions]:
    PlannedOperationTypesApi.KazakhIssuersStockTransactions,
  [PlannedOperationType.OptionsFuturesETCDerivatives]:
    PlannedOperationTypesApi.OptionsFuturesEtcDerivatives,
  [PlannedOperationType.Other]: PlannedOperationTypesApi.Other,
  [PlannedOperationType.RepoOperations]:
    PlannedOperationTypesApi.RepoOperations,
  [PlannedOperationType.SecuritiesTransferFromOtherBroker]:
    PlannedOperationTypesApi.SecuritiesTransferFromOtherBroker,
};
export const incomeSources: Record<IncomeSourcesType, IncomeSourcesApi> = {
  [IncomeSourcesType.BusinessIncome]: IncomeSourcesApi.BusinessIncome,
  [IncomeSourcesType.ExpenseSavings]: IncomeSourcesApi.ExpenseSavings,
  [IncomeSourcesType.FutureIncome]: IncomeSourcesApi.FutureIncome,
  [IncomeSourcesType.GiftContract]: IncomeSourcesApi.GiftContract,
  [IncomeSourcesType.Inheritance]: IncomeSourcesApi.Inheritance,
  [IncomeSourcesType.InvestmentIncome]: IncomeSourcesApi.InvestmentIncome,
  [IncomeSourcesType.Loan]: IncomeSourcesApi.Loan,
  [IncomeSourcesType.Other]: IncomeSourcesApi.Other,
  [IncomeSourcesType.PropertySale]: IncomeSourcesApi.PropertySale,
  [IncomeSourcesType.Salary]: IncomeSourcesApi.Salary,
  [IncomeSourcesType.Savings]: IncomeSourcesApi.Savings,
};

export const parseFinancingSourcesParams = (
  data: FinancingSourcesParam
): UpdateClientDto_Input['financingSources'] => {
  const res: UpdateClientDto_Input['financingSources'] = {
    otherBrokerRelationshipPurpose: data.otherBrokerRelationshipPurpose || null,
    otherIncomeSource: data.otherIncomeSource || null,
    otherPlannedOperationType: data.otherPlannedOperationType || null,
    incomeAmount: parsePrice(data.incomeAmount),
    plannedAssetAmount: parsePrice(data.plannedAssetAmount),
    incomeSourceFileIds: data.incomeSourceFileIds,
    incomeSources: data.incomeSources.map((v) => incomeSources[v]),
    brokerRelationshipPurposes: data.brokerRelationshipPurpose.map(
      (v) => broker[v]
    ),
    plannedOperationTypes: data.plannedOperationTypes.map(
      (v) => plannedOperationTypes[v]
    ),
  };

  return res;
};
