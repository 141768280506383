import { LoadingLayout } from 'components';
import { config } from 'config';
import { useInterval } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { usePageTitle } from 'libs/navigation';
import {
  OnboardingLayout,
  OnboardingStatus,
} from 'modules/onboarding/common/components';
import { STATUS_UPDATE_INTERVAL } from 'modules/onboarding/common/constants';
import { useClientOnboardingStatus } from 'modules/onboarding/common/hooks';
import { OnboardingProvider } from 'modules/onboarding/common/providers';

import { useOnboarding } from '../hooks/useOnboarding';
import { pages } from '../route';

interface Props {
  clientId: string;
}

export const ClientContainer = ({ clientId }: Props) => {
  const {
    currentClient,
    loading,
    initialRoutes,
    onChangeRoutes,
    refetch: refetchClient,
  } = useOnboarding(clientId);

  const { t } = useTranslation();

  usePageTitle(t('onboarding.pageTitle'));

  const onboardingStatus = useClientOnboardingStatus(currentClient);

  useInterval(
    () => {
      refetchClient();
    },
    onboardingStatus ? STATUS_UPDATE_INTERVAL : null
  );

  if (!currentClient || loading) {
    return <LoadingLayout />;
  }

  if (onboardingStatus) {
    return (
      <OnboardingStatus
        email={config.EMAIL ?? ''}
        onboardingStatus={onboardingStatus}
      />
    );
  }

  return (
    <OnboardingProvider
      clientId={currentClient.id}
      data={currentClient}
      initialRoutes={initialRoutes}
      pages={pages}
      renderChild={({ route, countSteps, currentStepIndex }) => {
        const Component = route.page.component;
        return (
          <OnboardingLayout
            key={route.pageKey}
            countSteps={countSteps}
            currentStepIndex={currentStepIndex}
          >
            <Component {...route.page.componentProps} />
          </OnboardingLayout>
        );
      }}
      onChangeRoutes={onChangeRoutes}
    />
  );
};
