import {
  TypedForm,
  FormErrors as FormErrorsBase,
  ObjectSchema,
} from 'libs/form';
import { TFunc } from 'libs/i18n';
import {
  BaseLicenseFormType,
  getBaseSchema,
} from 'modules/onboarding/legal/forms/companyLicenseForm';

export interface LicenseFormType extends BaseLicenseFormType {}

export const getSchema = (t: TFunc): ObjectSchema<BaseLicenseFormType> =>
  getBaseSchema(t);

export const { Field, Form, useFormContext, Submit } =
  TypedForm<BaseLicenseFormType>();

export type FormErrors = FormErrorsBase<BaseLicenseFormType>;
