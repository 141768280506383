import { config } from 'config';
import { OnboardingStatus } from 'modules/onboarding/common/components';
import { useKycOnboardingStatus } from 'modules/onboarding/common/hooks';

import { useKyc } from '../hooks';
import { LegalBeneficiaryView } from '../views';

interface Props {
  clientId: string;
}

export const LegalBeneficiaryContainer = ({ clientId }: Props) => {
  const { expirationHandler, loading, token, status } = useKyc(clientId);

  const onboardingStatus = useKycOnboardingStatus(status);

  if (onboardingStatus) {
    return (
      <OnboardingStatus
        email={config.EMAIL ?? ''}
        onboardingStatus={onboardingStatus}
      />
    );
  }

  return (
    <LegalBeneficiaryView
      expirationHandler={expirationHandler}
      loading={loading}
      token={token}
    />
  );
};
