// eslint-disable-next-line no-restricted-imports
import { LegalClientBeneficiaryFragment } from 'api/generated/graphql';
import { LegalClientBeneficiaryEntity } from 'api/types/entity';
import { lodash } from 'helpers';

import {
  parseCountryEntity,
  parseKyc,
  parsePepConnectionEntity,
  parseTaxResidenceEntity,
} from '../../../helpers';
import { parseLegalClientOnboardingStatus } from '../../helpers/parseLegalClientOnboardingStatus';

import { parseControlOverEntity } from './parseControlOverEntity';

export const parseLegalClientBeneficiaryEntity = (
  data: LegalClientBeneficiaryFragment
): LegalClientBeneficiaryEntity => ({
  id: data.id,
  firstName: data.firstName,
  lastName: data.lastName,
  middleName: data.middleName || undefined,
  legalClientId: data.legalClientId || undefined,
  legalClientOnboardingStatus: data.legalClientOnboardingStatus
    ? parseLegalClientOnboardingStatus(data.legalClientOnboardingStatus)
    : undefined,
  onboarding: {
    routes: lodash.compact(data.onboarding?.routes),
  },
  email: data.email,
  dateOfBirth: new Date(data.dateOfBirth),

  citizenships: data.citizenships
    ? lodash.compact(data.citizenships).map(parseCountryEntity)
    : undefined,
  taxResidences: data.taxResidences
    ? lodash.compact(data.taxResidences).map(parseTaxResidenceEntity)
    : undefined,
  placeOfBirth: data.placeOfBirth,
  registrationAddress: data.registrationAddress,
  residentialAddress: data.residentialAddress,
  pepConnection: parsePepConnectionEntity(data),
  controlOver: parseControlOverEntity(data),

  kyc: parseKyc(data.kyc),
});
