import { EffectCallback, useEffect } from 'react';

// React lifecycle hook that call mount and unmount callbacks, when component is mounted and un-mounted, respectively
export const useLifecycles = (
  mount: EffectCallback,
  unmount?: EffectCallback
) => {
  useEffect(() => {
    mount();
    return () => {
      if (unmount) {
        unmount();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
