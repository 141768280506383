import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';

import {
  useLegalClientLegalHeadFirstHeadSettingsSave,
  useLegalClientGetLegalHeadFirstHeadLazy,
  useLegalClientCompleteLegalHeadFirstHeadOnboarding,
  useLegalClientCreateLegalHeadFirstHead,
} from 'api/requests/onboarding/legal/legalHeadFirstHead';
import {
  LegalClientEntity,
  LegalClientRepresentativeEntity,
} from 'api/types/entity';
import { PersonalDataFormType } from 'modules/onboarding/common/forms/personalDataForm';

import { pages } from '../route';

export const useOnboarding = (
  currentClient: LegalClientRepresentativeEntity,
  legalClient: LegalClientEntity,
  legalHeadId: string,
  firstHeadId?: string
) => {
  const headId = useRef(firstHeadId);
  const [loading, setLoading] = useState(false);

  const { saveSettings } = useLegalClientLegalHeadFirstHeadSettingsSave();

  const { getFirstHead, firstHead } = useLegalClientGetLegalHeadFirstHeadLazy();

  const { complete } = useLegalClientCompleteLegalHeadFirstHeadOnboarding();

  const { createFirstHead } = useLegalClientCreateLegalHeadFirstHead();

  const initialRoutes = useMemo(() => {
    const routes = firstHead?.onboarding?.routes ?? [];
    return routes;
  }, [firstHead?.onboarding?.routes]);

  const handleCreateFirstHead = async (personalData: PersonalDataFormType) => {
    const firstHead = await createFirstHead(
      legalClient.id,
      legalHeadId,
      personalData
    );
    if (firstHead) {
      await getFirstHead(legalClient.id, legalHeadId, firstHead.id);
    }
    return firstHead;
  };

  const onChangeRoutes = useCallback(
    async (routes: string[]) => {
      if (routes && firstHeadId) {
        await saveSettings(legalClient.id, legalHeadId, firstHeadId, {
          routes,
          totalRoutes: pages.size,
        });
      }
    },
    [firstHeadId, saveSettings, legalClient.id, legalHeadId]
  );

  useLayoutEffect(() => {
    if (headId.current) {
      setLoading(true);
      getFirstHead(legalClient.id, legalHeadId, headId.current).finally(() =>
        setLoading(false)
      );
    }
  }, [getFirstHead, legalClient.id, legalHeadId]);

  const onComplete = async () =>
    complete(legalClient.id, legalHeadId, firstHeadId!);

  return {
    currentClient,
    firstHead,
    legalClient,
    loading,
    initialRoutes,
    onChangeRoutes,
    onComplete,
    createFirstHead: handleCreateFirstHead,
  };
};
