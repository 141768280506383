import {
  PageComponentProps as PageComponentPropsBase,
  RoutePage as RoutePageBase,
} from 'modules/onboarding/common/types';

export interface PageComponentProps extends PageComponentPropsBase {}

export interface RoutePage extends RoutePageBase<PageComponentProps> {}

export enum Page {
  taxResidency = 'taxResidency',
  registrationAddress = 'registrationAddress',
  name = 'name',
  license = 'license',
  stateRegistration = 'stateRegistration',
  contacts = 'contacts',
}
