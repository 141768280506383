import { CountryEntity } from 'api/types/entity';
import { useTranslation } from 'libs/i18n';
import { Stack, Typography } from 'libs/ui';

import {
  useFormContext,
  Field,
} from '../../../forms/onboardingRegistrationAddressForm';

import { RegistrationAddressFields } from './RegistrationAddressFields';

export interface FormContentProps {
  countriesLoading?: boolean;
  countries: CountryEntity[];
}

export const FormContent = ({
  countriesLoading,
  countries,
}: FormContentProps) => {
  const { values } = useFormContext();
  const { t } = useTranslation();

  return (
    <Stack spacing={32}>
      <Stack spacing={24} spacingMobile={16}>
        <Typography variant="subtitle" variantMobile="bodyBold">
          {t('onboarding.registrationAddress.titleOfBirth')}
        </Typography>
        <Field.Autocomplete
          label={t('onboarding.registrationAddress.country.label')}
          loading={countriesLoading}
          name="countryOfBirth"
          optionLabelKey="name"
          options={countries}
          optionValueKey="id"
          placeholder={t('onboarding.registrationAddress.country.placeholder')}
        />
        <Field.Text
          label={t('onboarding.registrationAddress.locality.label')}
          name="localityOfBirth"
          placeholder={t('onboarding.registrationAddress.locality.placeholder')}
        />
      </Stack>

      <Stack spacing={24} spacingMobile={16}>
        <Typography variant="subtitle" variantMobile="bodyBold">
          {t('onboarding.registrationAddress.titleRegistration')}
        </Typography>
        <RegistrationAddressFields
          countries={countries}
          countriesLoading={countriesLoading}
          variant="Registration"
        />
      </Stack>

      <Stack spacing={24} spacingMobile={16}>
        <Stack
          direction="row-exceptMobile"
          flexWrap="wrap"
          justifyContent="space-between"
          spacing={16}
        >
          <Typography variant="subtitle" variantMobile="bodyBold">
            {t('onboarding.registrationAddress.titleResidence')}
          </Typography>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            spacing={16}
          >
            <Typography variant="bodyMedium">
              {t('onboarding.registrationAddress.residencelabel')}
            </Typography>
            <Field.Switch name="residentialAddressIsSame" />
          </Stack>
        </Stack>

        {!values.residentialAddressIsSame && (
          <RegistrationAddressFields
            countries={countries}
            countriesLoading={countriesLoading}
            variant="Residence"
          />
        )}
      </Stack>
    </Stack>
  );
};
