import {
  CitizenshipStep,
  PepConnectionStep,
  PersonalDataStep,
  RegistrationAddressStep,
  TaxResidencyStep,
  ControlOverStep,
} from './steps';
import { Page, RoutePage } from './types';

const getItem = (
  page: Page,
  component: RoutePage['component'],
  componentProps: RoutePage['componentProps'] = { canGoBack: true }
): [Page, RoutePage] => [page, { component, componentProps }];

export const pages = new Map<Page, RoutePage>([
  getItem(Page.personalData, PersonalDataStep, { canGoBack: false }),
  getItem(Page.citizenship, CitizenshipStep),
  getItem(Page.taxResidency, TaxResidencyStep),
  getItem(Page.registrationAddress, RegistrationAddressStep),
  getItem(Page.pepConnection, PepConnectionStep),
  getItem(Page.сontrolOver, ControlOverStep),
]);
