// eslint-disable-next-line no-restricted-imports
import { OnboardingLegalClientFragment } from 'api/generated/graphql';
import { LegalClientEntity } from 'api/types/entity';
import { lodash } from 'helpers';

import { parseDebtsEntity, parseFileEntity } from '../../helpers';
import { parseWithdrawalAccountsEntity } from '../../helpers/parseWithdrawalAccountsEntity';

import { parseLegalClientBeneficiaryItemEntity } from './innerItemEntity/parseLegalClientBeneficiaryItemEntity';
import { parseLegalClientBranchItemEntity } from './innerItemEntity/parseLegalClientBranchItemEntity';
import { parseLegalClientHeadItemEntity } from './innerItemEntity/parseLegalClientHeadItemEntity';
import { parseLegalClientLegalHeadItemEntity } from './innerItemEntity/parseLegalClientLegalHeadItemEntity';
import { parseBusinessRelationshipEntity } from './parseBusinessRelationshipEntity';
import { parseLegalClientConstitutionalDocument } from './parseConstitutionalDocument';
import { parseFinancialMonitoringEntity } from './parseFinancialMonitoringEntity';
import { parseLegalClientActivityTypes } from './parseLegalClientActivityTypes';
import { parseLegalClientBankAccounts } from './parseLegalClientBankAccounts';
import { parseLegalClientFinancingSourcesEntity } from './parseLegalClientFinancingSourcesEntity';
import { parseLegalClientInvoice } from './parseLegalClientInvoice';
import { parseLegalClientInvoiceCurrency } from './parseLegalClientInvoiceCurrency';
import { parseLegalClientLicenseInfo } from './parseLegalClientLicenseInfo';
import { parseLegalClientOnboardingStatus } from './parseLegalClientOnboardingStatus';
import { parseLegalClientOrderSubmissionContactsEntity } from './parseLegalClientOrderSubmissionContactsEntity';
import { parseLegalClientStateRegistration } from './parseLegalClientStateRegistration';
import { parseLegalClientTaxResidency } from './parseLegalClientTaxResidency';
import { parseOrganizationalAndLegalFormEntity } from './parseOrganizationalAndLegalFormEntity';
import { parseRegistrationPlaceEntity } from './parseRegistrationPlaceEntity';

export const parseLegalClient = (
  data: OnboardingLegalClientFragment
): LegalClientEntity => ({
  id: data.id,
  fullName: data.fullName ?? undefined,
  shortName: data.shortName ?? undefined,
  fullNameEng: data.fullNameEng ?? undefined,
  shortNameEng: data.shortNameEng ?? undefined,
  registrationPlace: data.registrationPlace
    ? parseRegistrationPlaceEntity(data.registrationPlace)
    : undefined,
  legalAddress: data.legalAddress,
  actualAddress: data.actualAddress,
  activityTypes: parseLegalClientActivityTypes(data.activityTypes),
  organizationalAndLegalForm: parseOrganizationalAndLegalFormEntity(
    data.organizationalAndLegalForm ?? undefined
  ),
  taxResidences: data.taxResidences
    ? lodash.compact(data.taxResidences).map(parseLegalClientTaxResidency)
    : undefined,
  bankAccounts: parseLegalClientBankAccounts(data.bankAccounts),
  contacts: data.contacts,
  stateRegistration: parseLegalClientStateRegistration(data),
  debts: parseDebtsEntity(data.debts),
  licenseInfo: parseLegalClientLicenseInfo(data.licenseInfo),
  isLicensed: data.isLicensed ?? undefined,
  ownershipStructureFiles: data.ownershipStructureFiles
    ? lodash.compact(data.ownershipStructureFiles).map(parseFileEntity)
    : undefined,
  bodies: data.bodies ? lodash.compact(data.bodies) : undefined,
  constitutionalDocument: parseLegalClientConstitutionalDocument(
    data.constitutionalDocument
  ),
  beneficiaries: data.beneficiaries
    ? lodash
        .compact(data.beneficiaries)
        .map(parseLegalClientBeneficiaryItemEntity)
    : undefined,
  heads: data.heads
    ? lodash.compact(data.heads).map(parseLegalClientHeadItemEntity)
    : undefined,
  legalHeads: data.legalHeads
    ? lodash.compact(data.legalHeads).map(parseLegalClientLegalHeadItemEntity)
    : undefined,
  financingSource: parseLegalClientFinancingSourcesEntity(
    data.financingSources
  ),
  financialMonitoring: parseFinancialMonitoringEntity(data.financialMonitoring),

  businessRelationship: parseBusinessRelationshipEntity(
    data.businessRelationship
  ),
  withdrawalAccounts: parseWithdrawalAccountsEntity(data.withdrawalAccounts),
  orderSubmissionContacts: parseLegalClientOrderSubmissionContactsEntity(
    data.orderSubmissionContacts
  ),
  branches: data.branches
    ? lodash.compact(data.branches).map(parseLegalClientBranchItemEntity)
    : undefined,
  isDataAccuracyConfirmed: data.isDataAccuracyConfirmed ?? false,
  status: parseLegalClientOnboardingStatus(data.onboardingStatus),
  signaturesCardFiles: data.signaturesCardFiles
    ? lodash.compact(data.signaturesCardFiles).map(parseFileEntity)
    : undefined,

  unidentifiableBeneficiaryLetterFiles:
    data.unidentifiableBeneficiaryLetterFiles
      ? lodash
          .compact(data.unidentifiableBeneficiaryLetterFiles)
          .map(parseFileEntity)
      : undefined,

  invoice: data.invoice ? parseLegalClientInvoice(data.invoice) : undefined,

  invoiceCurrency: data.invoiceCurrency
    ? parseLegalClientInvoiceCurrency(data.invoiceCurrency)
    : undefined,
});
