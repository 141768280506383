// eslint-disable-next-line no-restricted-imports
import { OnboardingLegalClientRepresentativeFragment } from 'api/generated/graphql';
import { LegalClientRepresentativeEntity } from 'api/types/entity';
import { lodash } from 'helpers';

import {
  parseCountryEntity,
  parseKyc,
  parsePepConnectionEntity,
  parseTaxResidenceEntity,
} from '../../helpers';
import { parseBiographyEntity } from '../../helpers/parseBiographyEntity';

import { parseCompanyEntity } from './parseCompanyEntity';
import { parseRoleInCompanyEntity } from './parseRoleInCompanyEntity';

export const parseLegalClientRepresentative = (
  data: OnboardingLegalClientRepresentativeFragment
): LegalClientRepresentativeEntity => ({
  id: data.id,
  userId: data.userId,
  legalClientId: data.legalClientId || undefined,
  onboarding: {
    routes: lodash.compact(data.onboarding?.routes),
  },
  roleInCompany: data.roleInCompany
    ? parseRoleInCompanyEntity(data.roleInCompany)
    : undefined,
  citizenships: data.citizenships
    ? lodash.compact(data.citizenships).map(parseCountryEntity)
    : undefined,
  taxResidences: data.taxResidences
    ? lodash.compact(data.taxResidences).map(parseTaxResidenceEntity)
    : undefined,
  registrationAddress: data.registrationAddress,
  residentialAddress: data.residentialAddress,
  placeOfBirth: data.placeOfBirth,
  biography: data.biography ? parseBiographyEntity(data.biography) : undefined,
  pepConnection: parsePepConnectionEntity(data),
  kyc: parseKyc(data.kyc),
  company: parseCompanyEntity(data),
});
