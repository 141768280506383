import { MouseEvent, useState } from 'react';

// eslint-disable-next-line no-restricted-imports
import { ClickAwayListener } from '@mui/material';
import { useColor } from 'hooks';
import { Stack, Tooltip } from 'libs/ui';
import { Info } from 'libs/ui/Icon/icons';
import { ColorsDark, ColorsLight } from 'libs/ui/theme';

import { HintStyled } from './Hint.styles';
import { HintProps } from './types';

/**
 * Hint component.
 */
export const Hint = ({ title }: HintProps) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setOpen(true);
  };

  const color = useColor({
    dark: ColorsDark.blue150,
    light: ColorsLight.blue150,
  });

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Stack alignItems="center" direction="row">
        <Tooltip
          open={open}
          title={title}
          type="click"
          onClose={handleTooltipClose}
        >
          <HintStyled
            alignItems="center"
            direction="row"
            onClick={handleTooltipOpen}
          >
            <Info color={color} size={16} />
          </HintStyled>
        </Tooltip>
      </Stack>
    </ClickAwayListener>
  );
};
