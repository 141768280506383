import { getColor } from 'helpers';
import { styled } from 'libs/ui/styled';
import { ColorsDark, ColorsLight } from 'libs/ui/theme';

export const HeaderStyled = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.body,
  height: 72,
  width: '100%',
  display: 'flex',

  '.header-wrapper': {
    alignItems: 'center',
    justifyContent: 'space-between',
    display: 'flex',
    flex: 1,

    '.header-logo': {
      display: 'flex',
    },
  },

  '.header-themeSwitch': {
    backgroundColor: getColor(theme, {
      dark: ColorsDark.gray100,
      light: ColorsLight.blue50,
    }),
  },
}));
