import { useLogout } from 'api/requests';
import { useTranslation } from 'libs/i18n';
import { ROUTES } from 'libs/navigation';
import { useUser } from 'libs/providers';
import { LocalStorage } from 'libs/storage';
import { Button } from 'libs/ui';

export const LogoutButtonFeature = () => {
  const { user } = useUser();
  const { logout } = useLogout();

  const { t } = useTranslation();

  const onLogout = () => {
    logout();
    LocalStorage.clear();

    window.location.replace(ROUTES.login.fullPath);
  };

  return user ? (
    <Button
      label={t('common.logout')}
      mode="outlined-small"
      onClick={onLogout}
    />
  ) : null;
};
