import {
  CompanyNameStep,
  CompanyRegistrationAddressStep,
  CompanyStateRegistrationStep,
  HeadTypeInfoStep,
  TaxResidencyStep,
} from './steps';
import { FirstHeadsStep } from './steps/FirstHeadsStep';
import { LegalClientLegalHeadPage as Page, RoutePage } from './types';

const getItem = (
  page: Page,
  component: RoutePage['component'],
  componentProps: RoutePage['componentProps'] = { canGoBack: true }
): [Page, RoutePage] => [page, { component, componentProps }];

export const pages = new Map<Page, RoutePage>([
  getItem(Page.companyName, CompanyNameStep, { canGoBack: false }),
  getItem(Page.headTypeInfo, HeadTypeInfoStep),
  getItem(Page.taxResidency, TaxResidencyStep),
  getItem(Page.companyStateRegistration, CompanyStateRegistrationStep),
  getItem(Page.companyRegistrationAddress, CompanyRegistrationAddressStep),
  getItem(Page.firstHeads, FirstHeadsStep),
]);
