import { useColor } from 'hooks';
import { ColorsDark, ColorsLight } from 'libs/ui/theme';

import { SvgIcon } from '../SvgIcon';
import { SvgProps } from '../types';

export const Info = ({ size = 24, color, onClick }: SvgProps) => {
  const defaultColor = useColor({
    dark: ColorsDark.white,
    light: ColorsLight.black,
  });
  color = color || defaultColor;
  return (
    <SvgIcon size={size} onClick={onClick}>
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 16.0098V11.0098M12 8.00977V7.99977M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
          stroke={color}
          strokeLinecap="round"
          strokeWidth="2"
        />
      </svg>
    </SvgIcon>
  );
};
