import { useCallback, useMemo, useRef, useState } from 'react';

import { useLifecycles } from 'hooks';
import { useScrollToTop } from 'hooks/useScrollToTop';

import { RoutePage } from '../../../types';
import { OnboardingOnChangeRoutesFunc } from '../types';

export interface OnboardingNavigationProps {
  pages: Map<string, RoutePage>;
  initialRoutes: string[];
  onChangeRoutes?: OnboardingOnChangeRoutesFunc;
  onEnd?: () => void;
}

export type OnboardingPage = { pageKey: string; page: RoutePage };

export const useOnboardingNavigation = ({
  initialRoutes,
  pages,
  onChangeRoutes,
  onEnd,
}: OnboardingNavigationProps) => {
  const routes = useRef<string[]>(initialRoutes).current;
  const pagesEntries = useMemo(() => Array.from(pages.entries()), [pages]);

  const [route, setRoute] = useState<OnboardingPage>();

  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const addPage = useCallback(
    (page: string) => {
      routes.push(page);
      setCurrentPageIndex(pagesEntries.findIndex(([key]) => key === page));
    },
    [pagesEntries, routes]
  );

  /**  Returns the current page on the router */
  const getPageByRoute = useCallback((): OnboardingPage => {
    const pageName = routes[routes.length - 1];
    let page = pagesEntries.find((p) => p[0] === pageName);

    if (!page) {
      [page] = pagesEntries;
      setRoute({ pageKey: page[0], page: page[1] });
      addPage(page[0]);
    }

    return { pageKey: page[0], page: page[1] };
  }, [addPage, pagesEntries, routes]);

  /**  Go to next page */
  const goToNext = useCallback(() => {
    const { pageKey } = getPageByRoute();
    const pageIndex = pagesEntries.findIndex(([key]) => key === pageKey);

    if (pageIndex === -1) {
      throw Error('bad page');
    }
    if (pageIndex === pagesEntries.length - 1) {
      // end
      onEnd?.();
      return;
    }
    const page = pagesEntries[pageIndex + 1];

    addPage(page[0]);

    onChangeRoutes?.(
      routes,
      { currentPage: pageKey, nextPage: page[0] },
      'next'
    );
    setRoute({ pageKey: page[0], page: page[1] });
  }, [getPageByRoute, pagesEntries, addPage, onChangeRoutes, routes, onEnd]);

  // Go to prev page
  const goToBack = useCallback(() => {
    const currentPage = getPageByRoute();
    if (currentPage.page.componentProps.canGoBack === false) {
      return;
    }

    const currentPageIndex = pagesEntries.findIndex(
      (p) => p[0] === currentPage.pageKey
    );

    if (currentPageIndex === -1 || !currentPageIndex) {
      return;
    }

    const page = pagesEntries[currentPageIndex - 1];

    addPage(page[0]);

    onChangeRoutes?.(
      routes,
      {
        currentPage: currentPage.pageKey,
        nextPage: page[0],
      },
      'back'
    );
    setRoute({ pageKey: page[0], page: page[1] });
  }, [addPage, getPageByRoute, onChangeRoutes, pagesEntries, routes]);

  /** Sets the initial component for onboarding  */
  useLifecycles(() => {
    const page = getPageByRoute();
    setRoute(page);
    setCurrentPageIndex(
      pagesEntries.findIndex(([key]) => key === page.pageKey)
    );
  });

  // scroll to top when changing page
  useScrollToTop([route]);

  return {
    currentPageIndex,
    route,
    goToBack,
    goToNext,
  };
};
