import { useRef } from 'react';

import { useLegalClientRepresentativeSaveAddresses } from 'api/requests';
import { useServerErrorNotify } from 'hooks';
import { OnboardingRegistrationAddressFormType } from 'modules/onboarding/common/forms/onboardingRegistrationAddressForm';
import {
  getInitialData,
  getSubmitData,
} from 'modules/onboarding/common/helpers/registrationAddress';
import { useOnboardingCountries } from 'modules/onboarding/common/hooks';

import { useOnboardingContext } from './useOnboardingContext';

export const useRegistrationAddress = () => {
  const { goToBack, goToNext, data } = useOnboardingContext();
  const {
    saveAddresses,
    loading: submitLoading,
    error,
  } = useLegalClientRepresentativeSaveAddresses();

  const { countries, loading: countriesLoading } = useOnboardingCountries();

  const initialValues = useRef(getInitialData(data.currentClient)).current;

  const onSubmit = async (values: OnboardingRegistrationAddressFormType) => {
    if (values) {
      const submitData = getSubmitData(values);
      const res = await saveAddresses(data.currentClient.id, submitData);
      if (res) {
        goToNext();
      }
    }
  };

  useServerErrorNotify(error);

  return {
    onSubmit,
    initialValues,
    countries,
    countriesLoading,
    submitLoading,
    goToBack,
  };
};
