import { useRef } from 'react';

import { useLegalClientSaveLicenseInfo } from 'api/requests';
import { FileUpload, useFilesUpload, useServerErrorNotify } from 'hooks';
import { useTranslation } from 'libs/i18n';
import { useOnboardingCountries } from 'modules/onboarding/common/hooks';

import { CompanyLicenseFormType } from '../forms/companyLicenseForm';
import { getInitialData, getSubmitData } from '../helpers/companyLicense';

import { useOnboardingContext } from './useOnboardingContext';

export const useCompanyLicense = () => {
  const { goToBack, goToNext, data } = useOnboardingContext();
  const { loading, error, saveLicense } = useLegalClientSaveLicenseInfo();

  const { countries, loading: countriesLoading } = useOnboardingCountries();

  const { t } = useTranslation();

  const initialValues = useRef(getInitialData(data.legalClient!, t)).current;

  const onSubmit = async (values: CompanyLicenseFormType) => {
    if (values) {
      const submitData = getSubmitData(values);
      const res = await saveLicense(data.legalClient!.id, submitData);

      if (res) {
        goToNext();
      }
    }
  };

  const filesUpload: FileUpload[] | undefined =
    data.legalClient!.licenseInfo?.files.map(
      (v) =>
        new FileUpload({ name: v.name, size: v.size }, undefined, false, v.id)
    );

  const {
    files,
    isLoadingFile: submitDisabled,
    onRemoveFile,
    onAddFiles,
  } = useFilesUpload(filesUpload);

  useServerErrorNotify(error);

  return {
    onSubmit,
    goToBack,
    submitLoading: loading,
    initialValues,
    submitDisabled,
    countries,
    countriesLoading,
    files,
    onAddFiles,
    onRemoveFile,
  };
};
