import { useRef } from 'react';

import { useClientRepresentativeSaveAddresses } from 'api/requests';
import { useServerErrorNotify } from 'hooks';
import { OnboardingRegistrationAddressFormType } from 'modules/onboarding/common/forms/onboardingRegistrationAddressForm';
import {
  getInitialData,
  getSubmitData,
} from 'modules/onboarding/common/helpers/registrationAddress';
import { useOnboardingCountries } from 'modules/onboarding/common/hooks';

import { useOnboardingContext } from './useOnboardingContext';

export const useRegistrationAddress = () => {
  const {
    error,
    loading: submitLoading,
    saveAddresses,
  } = useClientRepresentativeSaveAddresses();

  const { goToBack, goToNext, data } = useOnboardingContext();

  const { countries, loading: countriesLoading } = useOnboardingCountries();

  const initialValues = useRef(getInitialData(data.currentClient)).current;

  const onSubmit = async (values: OnboardingRegistrationAddressFormType) => {
    if (values) {
      const submitData = getSubmitData(values);
      await saveAddresses(data.currentClient.id, submitData);
      goToNext();
    }
  };

  useServerErrorNotify(error);

  return {
    onSubmit,
    goToBack,
    initialValues,
    countries,
    countriesLoading,
    submitLoading,
  };
};
