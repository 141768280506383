import { FormLayout } from 'components';
import { getColor } from 'helpers';
import { Stack } from 'libs/ui';
import { CSSObject, styled } from 'libs/ui/styled';
import { ColorsDark, ColorsLight } from 'libs/ui/theme';

export const FormLayoutStyled = styled(FormLayout)(({ theme }) => ({
  backgroundColor: getColor(theme, {
    light: ColorsLight.gray50,
    dark: ColorsDark.black,
  }),

  '.registrationSelectType-content': {
    [theme.breakpoints.down('mobile')]: {
      marginTop: 32,
    } as CSSObject,
  },
}));

export const ButtonsStyled = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'center',
  gap: 32,

  [theme.breakpoints.down('tablet')]: {
    flexDirection: 'column',
    gap: 16,
  },
}));
