import { Stack } from 'libs/ui';
import { CSSObject, styled } from 'libs/ui/styled';

export const ItemStyled = styled(Stack)(() => ({
  overflow: 'hidden',

  '.listItem-textContent': {
    overflow: 'hidden',

    '.listItem-text': {
      textOverflow: 'ellipsis',
      textWrap: 'nowrap',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    } as CSSObject,
  },
}));

export const ErrorStyled = styled(Stack)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.error}`,
  marginTop: 16,
  paddingTop: 6,
}));
